import React, { Component } from 'react';
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { api } from '../../views'
import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/VKShare-01.png'
import sygnet from '../../assets/img/brand/VKShare-01.png'
import '../../index.css'
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  handleLogout() {
    localStorage.removeItem("RFTK-GK")
    // this.props.history.push('/login');
    api.dataService.logout()
    window.location.href = '#/login'

  }
  handleUpdate() {
    window.location.href = '#/user/profile'

  }
  navBarHeader() {
    return (
      <React.Fragment>
        <Nav className="d-md-down-none" navbar>
          {/* <NavItem className="px-3">
            <NavLink href="/">VKShare</NavLink>
          </NavItem> */}
          <NavItem className="px-3">
            <NavLink href="#/users" >Quản lý người dùng</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/rating" target="_blank">Đánh giá</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/registration" target="_blank">Đăng kí nhận đồ</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/push-notice" target="_blank">Gửi thông báo hệ thống</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#/push-notice-for-app-user" target="_blank">Gửi thông báo người dùng app</NavLink>
          </NavItem>

        </Nav>
        {/* <Nav className="px-3" >
          <marquee direction="right" >
            VKShare - Chia sẻ hoa hồng
</marquee>
        </Nav> */}
      </React.Fragment>
    )
  }
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    var name = localStorage.getItem('name')
    var avatar = localStorage.getItem('avatar')
    let data = localStorage.getItem('roleId') || ""
    let roldIdNavbar = data.includes('5') || data.includes('2')
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 50, height: 50, alt: 'Giat Ky' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'Giat Ky' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        {roldIdNavbar ? '' : this.navBarHeader()}


        <Nav className="ml-auto" navbar>
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-bell"></i><Badge pill color="danger">New</Badge></NavLink>
          </NavItem> */}
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-list"></i></NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem> */}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              {avatar ? <img src={avatar} className="img-avatar" alt={name} /> : <img src={'assets/img/avatars/1.jpg'} className="img-avatar" alt="admin@bootstrapmaster.com" />}  <span style={{ marginRight: '10%' }}><b>Xin chào ! {name} </b></span>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }} className="animated fadeIn">
              <DropdownItem header tag="div" className="text-center"><strong>Thông tin tài khoản</strong></DropdownItem>
              <DropdownItem onClick={this.handleUpdate.bind(this)}><i className="fa fa-bell-o"></i>Cập nhật thông tin cá nhân</DropdownItem>
              {/* <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem>
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem><i className="fa fa-user"></i> Profile</DropdownItem>
              <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem>
              <DropdownItem divider />
              <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem> */}
              <DropdownItem onClick={this.handleLogout.bind(this)}><i className="fa fa-lock"></i>Đăng xuất</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" />
        <AppAsideToggler className="d-lg-none" mobile /> */}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
