import React, { Component } from 'react';
import { Alert, Table, Progress, Card, FormGroup, Col, Button, Form, Input, Label, Row, Pagination, PaginationItem, PaginationLink, } from 'reactstrap';
import { api } from '../../../views'
import moment from 'moment'
import qs from 'query-string'
class Debts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listDebts: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: [],
      userId: '',
      storeId: [],
    };

  }

  async handleClick(event) {
    let search = '?'
    let storeId = qs.parse(this.props.location.search).storeId;
    let userId = qs.parse(this.props.location.search).userId;

    if (storeId) {
      search += '&storeId=' + storeId
    }
    if (userId) {
      search += '&userId=' + userId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actGetDebts(storeId, userId)
  }
  componentDidMount() {
    this.actGetDebts()
  }
  async actGetDebts(storeId, userId) {
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let storeIds = [];
    if (storeId) { storeIds.push(+storeId) }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actGetDebts(skip, limit, storeIds, userId)
    console.log('888', result)
    let count = result.data.count;


    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ listDebts: result.data.data, next, prev, count, currentPage, last: maxPage || 1, pagesNumber });

  }
  formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }

  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { storeId, userId } = this.state;
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&storeId=' + (storeId || '') + '&userId=' + (userId || ''))
    this.actGetDebts(storeId, userId)

  }
  render() {
    var { listDebts, currentPage, prev, next, count, pagesNumber, last, storeId, userId } = this.state
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>

            <Form onSubmit={this.onSubmitSearch}>
              <Row form>

                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleState">ID nhân viên</Label>
                    <Input type="text" id="userId" name="userId" value={userId} onChange={this.onChange.bind(this)} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleState">ID cửa hàng</Label>
                    <Input type="text" id="storeId" name="storeId" value={storeId} onChange={this.onChange.bind(this)} />
                  </FormGroup>
                </Col>

              </Row>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
              </FormGroup>
            </Form>
            <Alert color="warning">
              <i className="fa fa-align-justify"></i> {listDebts.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
            </Alert>
            {!listDebts ? <Alert color="warning" className="center">
              Không có dữ liệu !
      </Alert> :
              <Card>
                <Table hover responsive className="table-outline mb-0 d-none d-sm-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center"><i className="icon-people"></i></th>
                      <th className="text-left">Thông tin nhân viên</th>
                      <th>Khoản nợ</th>
                      <th className="text-left">Địa chỉ cửa hàng</th>
                      <th className="text-left">Tên của hàng</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listDebts.map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">
                            {index + 1}
                          </td>
                          <td className="text-left">
                            <div className="small">
                              <span className="bold">Tên nhân viên</span> | <span>{a.fullName}</span>
                            </div>
                            <div className="small">
                              <span className="bold">Tài khoản</span> | <span>{a.account}</span>
                            </div>
                          </td>
                          <td>
                            <div className="clearfix">
                              <div className="float-left">
                                <strong>{this.formatCurrency(a.debt)} VNĐ</strong>
                              </div>
                              <div className="float-right">
                                <small className="text-muted">{this.formatCurrency(a.maxDebt)} VNĐ</small>
                              </div>
                            </div>
                            <Progress className="progress-xs" color="danger" value={(a.debt) * 100 / (a.maxDebt)} />
                          </td>
                          <td className="text-left">
                            <span style={{ fontSize: 14 + 'px' }}>{a.storeAddress}</span>
                          </td>
                          <td className="text-left">
                            {/* <div className="small text-muted">{a.storeName}</div> */}
                            <strong>{a.storeName}</strong>
                          </td>
                        </tr>)
                    })}


                  </tbody>
                </Table> </Card>}
            <div style={{ display: 'table', margin: '0 auto' }}>
              {pagesNumber.length == 1 ? '' : <Pagination >
                <PaginationItem>
                  {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                  }
                </PaginationItem>
                <PaginationItem>
                  {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                  }
                </PaginationItem>
                {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                  return (<Pagination key={index}>
                    <PaginationItem active={currentPage == (pageNumber)} >
                      {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                        {pageNumber}
                      </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                          ...
                          </PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>)

                })
                }

                <PaginationItem>
                  {
                    next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                  }
                </PaginationItem>

                <PaginationItem>
                  {
                    currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                  }
                </PaginationItem>
              </Pagination>}

            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

export default Debts;
