import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Badge, Modal,
  ModalBody,
  Form,
  FormGroup,
  Row, Col,
  Input,
  InputGroup,
  ModalFooter,
  InputGroupText, Label,
  ModalHeader, ListGroup, ListGroupItem, Alert, PaginationLink, Table
} from 'reactstrap';
import { api } from '../..'
import { connect } from "react-redux";
import moment from 'moment'


class Ratings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      info: false,
      nameError: '',
    };
    this.toggleInfo = this.toggleInfo.bind(this);
  }
  validate = () => {
    let nameError = "";
    var { name } = this.state;
    if (!name) {
      nameError = 'Tên không được để trống !'
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    }
    return true
  }

  toggleInfo() {
    this.setState({
      info: !this.state.info,
    });
  }
  componentDidMount() {
    this.actFetchRatingReviews()
  }
  async actFetchRatingReviews() {
    await api.dataService.actFetchRatingRequest('', 5, '', '', [{ "id": "DESC" }, { "createdAt": "ASC" }])
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  async actSeeMore() {
    await api.dataService.actFetchRatingRequest('', 30, '', '', [{ "id": "DESC" }, { "createdAt": "ASC" }])
  }
  async actDeleteRating(a) {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.actDeleteRatingRequest(a.id)
    }
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name } = this.state;

    if (isValid) {
      var rating = {
        name: name
      }
      // api.dataService.actUpdateRoomRequest(rooms)
      // if (id) {
      //     api.dataService.actUpdateRoomRequest(rooms)
      // }
      // else {
      //     api.dataService.actAddRoomRequest(rooms)
      // }
      await api.dataService.actAddRatingRequest(rating)

      this.setState({
        info: !this.state.info,
        name: ''
      })
    }

    // api.dataService.actFetchRoomsRequest();
  }
  render() {
    var { name, nameError } = this.state
    var { ratingReviews } = this.props;
    ratingReviews = this.props.ratingReviews.ratings
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <h3 className="center">Danh sách đánh giá </h3>
            <Alert color="light">
              <i className="fa fa-align-justify"></i> {ratingReviews.length > 0 ? `Dữ liệu (${ratingReviews.length} mục)` : 'Đang xử lý ...'}
            </Alert>
            <Button color="link" onClick={this.toggleInfo} className="mr-1">Thêm đánh giá</Button>
            {!ratingReviews ? <div>Không có dữ liệu !</div> : ratingReviews.map((a, index) => {
              return (<ListGroup key={index}>
                <ListGroupItem tag="a" className="summary" action>{a.name}   <Button onClick={() => this.actDeleteRating(a)} color="warning" className="float-right">Xóa</Button>{' '}</ListGroupItem>

              </ListGroup>)
            })}
          </Col>
        </Row>
        <Button color="info" style={{ display: 'table', margin: '20px auto' }} onClick={this.actSeeMore.bind(this)}>Xem thêm</Button>{' '}
        <Modal isOpen={this.state.info} toggle={this.toggleInfo}
          className={'modal-info ' + this.props.className}>
          <ModalHeader toggle={this.toggleInfo}>Thêm đánh giá nhân viên</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup row>
                <Label for="name" sm={3}>Nhận xét</Label>
                <Col sm={9}>
                  <Input type="text" name="name" id="name" value={name} placeholder="Nhập vào nhận xét " onChange={this.onChange.bind(this)} />
                </Col>
              </FormGroup>
              {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}

              <FormGroup className="form-actions">
                <Button color="primary" className="float-right" style={{ margin: '0 20px' }}>Lưu</Button>{' '}
              </FormGroup>
            </Form>
          </ModalBody>

        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    ratingReviews: state.ratingReviews,
  }
}
export default connect(mapStateToProps)(Ratings);