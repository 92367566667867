import React, { Component } from 'react';
import {
  Col, Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table

} from 'reactstrap';
import moment from 'moment'
import qs from 'query-string';
import { api } from '../../../views'
import '../../../index.css'
import _ from "lodash"
class PushNoticeForAppUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      info: '',
      warning: '',
      dangerous: '',
      selected: 'info',
      from: '',
      to: '',
      storeInfo: [],
      stores: [],
      selectedStores: [],
      selectedStoresID: []
    };
  }


  componentDidMount() {
    this.actGetStoreList()
  }
  handleSelectStore = async (e) => {
    let selected = this.state.stores.find(s => { return s.id == e.target.value })
    this.setState({
      selectedStores: _.uniq([...this.state.selectedStores, selected]),
      selectedStoresID: _.uniq([...this.state.selectedStoresID, selected.id])
    }, () => {
      console.log(this.state.selectedStoresID)
    })
  }

  handleUnSelect = (id) => {
    let unSelectedItem = this.state.selectedStores.find(s => { return s.id == id })
    let removeIndex = this.state.selectedStores.indexOf(unSelectedItem)
    this.setState({
      selectedStores: [...this.state.selectedStores.slice(0, removeIndex), ...this.state.selectedStores.slice(removeIndex + 1)],
      selectedStoresID: [...this.state.selectedStoresID.slice(0, removeIndex), ...this.state.selectedStoresID.slice(removeIndex + 1)]
    }, () => {
      console.log(this.state.selectedStores)
      console.log(this.state.selectedStoresID)

    })
  }
  async actGetStoreList() {
    let result = await api.dataService.actFetchStoresRequest("", "", "", "", "")
    this.setState({
      stores: result.data.data
    }, () => {
      console.log("aaa", this.state.stores)
      console.log("ccc", this.state.selectedStoresID)

      let selectedStores = [];
      this.state.stores.map(s => {
        this.state.selectedStoresID.map(sid => {
          s.id == sid && selectedStores.push(s)
        })
      })

      console.log("bbb", selectedStores)

      this.setState({
        selectedStores
      })
    })

  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }

  async actPushNotice() {
    const { content, title, selected, from, to, selectedStoresID } = this.state;
    let notice = {}
    if(selected == 'advertisement') {
      notice = {
        title: title,
        content: content,
        level: selected,
        from: moment(from).valueOf(),
        to: moment(to).valueOf(),
        listStore: selectedStoresID
      }
    }
    else {
      notice = {
        title: title,
        content: content,
        level: selected
      }
    }
    var result = await api.dataService.actPushNoticeForAppUser(notice)
    if (result.status === 200) {
      api.api.showMessage(result.data.message, 'Thông báo')
      this.setState({ title: '', content: '', selected: '', from: '', to: '', selectedStores: '[]' })
    }

  }
  render() {
    const { content, title, info, warning, dangerous, from, to, storeInfo, stores, selectedStores, selected } = this.state;
    return (
      <div className="customerreviews">
        <Alert color="danger" className="center">
          Gửi thông báo
      </Alert>
        <Form lg>
          <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold">Tiêu đề : </Label>
            <Col sm={9}>
              <Input type="text" name="title" value={title} id="title" min={0} step={1} onChange={this.onChange.bind(this)} placeholder="Nhập tiêu đề ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleText" sm={3} className="bold">Nội dung :</Label>
            <Col sm={9}>
              <Input type="textarea" style={{ maxHeight: '100px' }} name="content" value={content} id="content" onChange={this.onChange.bind(this)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleCheckbox" sm={3} className="bold">Level :</Label>
            <Col sm={9}>
              <div>
                <CustomInput type="radio" id="info"
                  checked={this.state.selected === 'info'} value="info"
                  onChange={(e) => this.setState({ selected: e.target.value })}
                  label="Thông báo" />
                <CustomInput type="radio" id="ads"
                  checked={this.state.selected === 'advertisement'} value="advertisement"
                  onChange={(e) => this.setState({ selected: e.target.value })} label="Quảng cáo" />
                {/* <CustomInput type="radio" id="dangerous"
                  checked={this.state.selected === 'dangerous'} value="dangerous"
                  onChange={(e) => {
                    console.log(e.target.value)
                    this.setState({ selected: e.target.value })
                  }} label="Nguy hiểm" /> */}
              </div>
              <br></br>
            </Col>

            {
              selected == 'advertisement' && (
                <>

                  <Col md={12}>
                    <FormGroup row>
                      <Col sm={3}><Label for="exampleEmail" className="bold">Thời gian áp dụng:</Label></Col>
                      <Col sm={9}><Input type="date" name="from" id="fromDate" value={from} onChange={this.onChange.bind(this)} placeholder="date placeholder" /></Col>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup row>
                      <Col sm={3}><Label for="exampleEmail" className="bold">Thời gian kết thúc:</Label></Col>
                      <Col sm={9}><Input type="date" name="to" id="toDate" value={to} onChange={this.onChange.bind(this)} placeholder="date placeholder" /></Col>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup row>
                      <InputGroup>
                        <Col sm={3}><Label for="exampleEmail" className="bold">Cửa hàng áp dụng: </Label></Col>
                        <Col sm={9}><Input type="select" onChange={this.handleSelectStore}>
                          {
                            stores.map((store) => {
                              return <option key={store.id} value={store.id}>{store.name}</option>
                            })
                          }
                        </Input></Col>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Table size="sm">
                    <thead>
                      <tr>
                        <th>Cửa hàng đã chọn</th>
                        <th>Hành động</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        selectedStores.map(store => {
                          return (
                            <tr key={store.id}>
                              <td>{store.name}</td>
                              <td><Button onClick={() => this.handleUnSelect(store.id)} color="danger">Bỏ chọn</Button></td>
                            </tr>
                          )

                        })
                      }
                    </tbody>
                  </Table>
                </>
              )
            }
          </FormGroup>
          <FormGroup check row>
            <Col className="right">
              <Button className="right" color="success" onClick={this.actPushNotice.bind(this)}>Gửi</Button>
            </Col>
          </FormGroup>
        </Form>

      </div>
    )
  }
}

export default PushNoticeForAppUser;

