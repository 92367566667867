import React, { Component } from 'react';
import {
  Badge, Card, CardBody, CardHeader, Col, Row, Table, Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader
} from 'reactstrap';
import { AppSwitch } from '@coreui/react'
import { api } from '../..'
// import usersData from './UsersData'
import { connect } from "react-redux";

function UserRow(props) {
  const config = props.config
  // console.log('ccc', config)
  const userLink = `#/config/configmanagament/${config.id}`
  function stringToDotDotDot(input, length) {
    if (input && typeof input === 'string' && input.length > length) {
      return input.substr(0, length) + '...'
    }
    return input;
  }
  async function handleUpdateNo() {
    // if (confirm('Bạn chắc chắn muốn hủy đơn đăng kí này không ?')) { //eslint-disable-line
    //   api.dataService.actDeleteUserRequest(user.id)
    //   // api.dataService.actFetchUsersRequest(0, 10, '', '', '')
    //   window.location.reload(true);
    // }

  }
  async function handleUpdateYes() {
    const user = props.user
    var id = user.id
    var users = {
      id: id,
      isVerify: true
    }
    if (confirm('Bạn chắc chắn muốn duyệt người dùng ?')) { //eslint-disable-line
      await api.dataService.actUpdateUSerRequest(users);
      // api.dataService.actFetchUsersRequest(0, 10, '', '', '')
    }

    window.location.reload(true);

  }
  return (
    <tr >
      <th scope="row"><a href={userLink}>{config.key}</a></th>
      <td><a href={userLink}>{stringToDotDotDot(config.value, 40)}</a></td>
      <td>{config.description}</td>
    </tr >
  )
}

class Configs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      username: '',
      password: '',
      email: '',
      fullname: '',
      phone: '',
      identityCard: '',
      description: '',
      address: '',
      roleIds: '',
      roleId: '',
      id: '',
      isLocker: null,
      users: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    api.dataService.actFetchConfigsRequest('', 100, '', '', '')
  }

  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    var { username, fullname, email, password, address, phone, description, identityCard, roleId, id } = this.state;
    var user = {
      account: username,
      fullName: fullname,
      email: email,
      password: password,
      address: address,
      phone: phone,
      description: description,
      identityCard: parseInt(identityCard),
      roleIds: parseInt(roleId)
    }
    // api.dataService.actUpdateRoomRequest(rooms)
    // if (id) {
    //     api.dataService.actUpdateRoomRequest(rooms)
    // }
    // else {
    //     api.dataService.actAddRoomRequest(rooms)
    // }
    await api.dataService.actAddUserRequest(user)

    this.setState({
      success: !this.state.success,
    })
    // api.dataService.actFetchRoomsRequest();
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  render() {
    var { username, fullname, email, users, password, address, phone, description, identityCard, roleIds, roleId } = this.state;
    var { listConfigs } = this.props;
    listConfigs = this.props.listConfigs.configs
    {/* console.log('xxx', listConfigs) */ }

    // const userList = usersData.filter((user) => user.data.id < 10)

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12} >
            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm</Button>
          </Col>
          <br />
          <br />
          <br />
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i>Dữ liệu
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th scope="col">Key</th>
                      <th scope="col">Giá trị</th>
                      <th scope="col">Mô tả</th>
                      {/* <th scope="col">Khóa tài khoản</th> */}
                      {/* <th scope="col" style={{ textAlign: 'center' }}>Hành động</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {listConfigs != "" ? listConfigs.map((config, index) =>
                      <UserRow key={index} config={config} />
                    ) : ''}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Add User</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tài khoản</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="username" name="username" value={username} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mật khẩu</InputGroupText>
                  </InputGroupAddon>
                  <Input type="password" id="password" name="password" value={password} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email</InputGroupText>
                  </InputGroupAddon>
                  <Input type="email" id="email" name="email" value={email} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Họ và tên</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="fullname" name="fullname" value={fullname} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Địa chỉ</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="address" name="address" value={address} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mô tả chi tiết</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>identityCard</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="identityCard" name="identityCard" value={identityCard} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Số điện thoại</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="phone" name="phone" value={phone} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>RoleIds</InputGroupText>
                  </InputGroupAddon>
                  <Input type="select" name="roleId" value={roleId} id="roleId" onChange={this.onChange.bind(this)} >
                    <option value="">-- select --</option>
                    {roleIds.length > 0 ? roleIds.map(a => {
                      return (< option key={a.id} value={a.id} > {a.name}</option>)
                    }) : ''}

                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Submit</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal> */}
        <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Thêm giá trị</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Giá trị</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="username" name="username" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mô tả</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="password" name="password" />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Submit</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listConfigs: state.listConfigs,
  }
}
export default connect(mapStateToProps)(Configs);

