import React, { Component } from 'react';
import {
  Button, Row, Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Table, Modal,
  ModalBody,
  Form, Alert,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader
} from 'reactstrap';
import { api } from '../../../views'
import { connect } from "react-redux";
import qs from 'query-string'
import '../../../index.css'


function FormError(props) {
  /* nếu isHidden = true, return null ngay từ đầu */
  if (props.isHidden) { return null; }

  return (<p style={{ color: 'red', width: '100%' }}><i>{props.errorMessage}</i></p>)
}

function StoreRow(props) {
  const index = props.index
  const storepartner = props.storepartner
  // console.log('bbb', storepartner)
  const storepartnerLink = `#/quanlycuahang/storepartner/${storepartner.id}`

  async function handleDeleteStorePartner() {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.actDeleteStorePartnerRequest(storepartner.id)
    }

  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={storepartnerLink}>{index + 1}</a></th>
      <td className="text-center"><a href={storepartnerLink}>{storepartner.name}</a></td>
      <td className="text-center">{storepartner.address}</td>
      <td className="text-center"><a href={storepartnerLink}>{storepartner.phone}</a></td>
      <td className="text-center">{storepartner.email}</td>
      <td className="text-center">  <Button outline color="success" size="sm" block onClick={handleDeleteStorePartner}>Xóa xưởng</Button></td>
    </tr >
  )
}

class StorePartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: '',
      nameError: '',
      address: '',
      addressError: '',
      id: '',
      phone: '',
      phoneError: '',
      email: '',
      emailError: '',
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  async handleClick(event) {
    await this.props.history.push('?page=' + event)
    this.actGetStorePartners()
  }

  validate = () => {
    let emailError = "";
    let nameError = "";
    let addressError = "";
    let phoneError = "";
    var { name, phone, email, address } = this.state;
    const regexp = /^0\d{9,10}$/;
    if (!name) {
      nameError = 'Tên xưởng không được để trống !'
    }
    if (!address) {
      addressError = 'Trường này không được để trống !'
    }
    if (!email.includes("@")) {
      emailError = 'Email không đúng định dạng !';
    }
    if (!phone.match(regexp)) {
      phoneError = 'Số điện thoại phải bắt đầu bằng 0 và có 10-11 chữ số !'
    }
    if (emailError || phoneError || nameError || addressError) {
      this.setState({ emailError, phoneError, nameError, addressError });
      return false;
    }
    return true
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    this.actGetStorePartners()
  }
  async actGetStorePartners(where, sort) {

    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchStorePartnerRequest(skip, limit, '', where, sort)
    let count = result.count

    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ next, prev, count, currentPage, prev: currentPage - 1, next: currentPage + 1, last: maxPage || 1, pagesNumber });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name, phone, email, address } = this.state;
    if (isValid) {
      var storepartner = {
        name: name,
        address: address,
        phone: phone,
        email: email

      }
      // api.dataService.actUpdateRoomRequest(rooms)
      // if (id) {
      //     api.dataService.actUpdateRoomRequest(rooms)
      // }
      // else {
      //     api.dataService.actAddRoomRequest(rooms)
      // }
      await api.dataService.actAddStorePartnerRequest(storepartner)

      this.setState({
        success: !this.state.success,
      })
    }

    // api.dataService.actFetchRoomsRequest();
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { name, phone } = this.state;
    let where = {};
    let sort = [];
    if (name) {
      where.name = { 'contains': name };
      sort.name = { id: 'ASC' }
    }
    if (phone) {
      where.phone = { 'contains': phone }
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1)
    this.actGetStorePartners(where, sort)


  }


  render() {
    var { name, phone, email, address, addressError, currentPage, prev, next, pagesNumber, last, emailError, nameError, phoneError } = this.state;
    var { listStorePartners } = this.props;
    listStorePartners = this.props.listStorePartners.storepartners

    return (
      <Row className="animated fadeIn">
        <Col xs={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
            <CardBody>
              <Form onSubmit={this.onSubmitSearch}>
                <Row form>
                  {/* <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã</Label>
                        <Input type="text" id="id" name="id" value={id} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                  <Col md={4}>
                    <FormGroup>
                      <Label for="exampleState">Tên</Label>
                      <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    </FormGroup>
                  </Col>
                  {/* <Col md={4}>
                    <FormGroup row>
                      <Label for="exampleZip">Số điện thoại </Label>
                      <Input type="number" id="phone" name="phone" value={phone} min='0' step='1' onChange={this.onChange.bind(this)} />

                    </FormGroup>
                  </Col> */}
                </Row>
                <FormGroup className="form-actions">
                  <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12} >
          <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm mới xưởng</Button>
        </Col>
        <br />
        <br />
        <br />
        <Col xs={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {listStorePartners.length > 0 ? `Danh sách xưởng (${listStorePartners.length} mục)` : 'Đang xử lý ...'}
            </CardHeader>
            <CardBody>
              {listStorePartners.length > 0 ?
                <Table hover bordered striped responsive size="sm">

                  <thead>
                    <tr>
                      <th className="text-center">#</th>
                      <th className="text-center">Tên xưởng</th>
                      <th className="text-center">Địa chỉ</th>
                      <th className="text-center">Số điện thoại</th>
                      <th className="text-center">Email</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listStorePartners.map((storepartner, index) =>
                      <StoreRow key={index} storepartner={storepartner} index={index} />
                    )}

                  </tbody>
                </Table> : <Alert color="warning" className="center">
                  Không có dữ liệu !
      </Alert>}
              <div style={{ display: 'table', margin: '0 auto' }}>
                {pagesNumber.length == 1 ? '' : <Pagination >
                  <PaginationItem>
                    {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                    }
                  </PaginationItem>
                  <PaginationItem>
                    {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                    }
                  </PaginationItem>
                  {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                    return (<Pagination key={index}>
                      <PaginationItem active={currentPage == (pageNumber)} >
                        {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                          {pageNumber}
                        </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            ...
                          </PaginationLink>
                        }
                      </PaginationItem>
                    </Pagination>)

                  })
                  }

                  <PaginationItem>
                    {
                      next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                    }
                  </PaginationItem>

                  <PaginationItem>
                    {
                      currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                    }
                  </PaginationItem>
                </Pagination>}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Thêm mới xưởng</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tên</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Địa chỉ</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="address" name="address" value={address} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {addressError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{addressError}</i> </p> : ''}
              </FormGroup>

              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email</InputGroupText>
                  </InputGroupAddon>
                  <Input type="email" id="email" name="email" value={email} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {emailError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{emailError}</i> </p> : ''}
              </FormGroup>

              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Số điện thoại</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="phone" name="phone" value={phone} min='0' step='1' onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                  <br />


                </InputGroup>
                {phoneError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{phoneError}</i> </p> : ''}
              </FormGroup>

              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Lưu</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listStorePartners: state.listStorePartners,
  }
}
export default connect(mapStateToProps)(StorePartners);


