import React, { Component } from 'react';
import {
    Button, Card, CardBody, CardHeader, Modal,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Col, Row,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    ModalHeader, Alert, Table
} from 'reactstrap';
import { api } from '../../../views'
class AppProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successProduct: false,
            success: false,
            appProductList: '',
            areaServiceCategory: '',
            id: '',
            name: '',
            nameError: '',
            idProduct: '',
            nameProduct: '',
            nameProductError: '',
            logoProduct: '',
            logoProductError: '',
            priceProduct: '',
            priceProductError: ''
        };
        this.toggleSuccess = this.toggleSuccess.bind(this);
        this.toggleSuccessProduct = this.toggleSuccessProduct.bind(this);
    }
    componentDidMount() {
        this.getAreaService()
        this.getProductList()
        if (this.props.match.params.id) {
            this.getCategoryAppId()
        }
    }
    async getProductList() {
        var id = this.props.match.params.id
        var result = await api.dataService.getCategoryApp(id, "3")
        this.setState({
            appProductList: result.data.data
        })
    }
    onChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        await this.setState({
            [name]: value
        });
    }
    toggleSuccess() {
        this.setState({
            success: !this.state.success,
        });
    }
    toggleSuccessProduct(id, icon, name, price) {
        this.setState({
            successProduct: !this.state.successProduct,
            idProduct: id,
            nameProduct: name,
            logoProduct: icon,
            priceProduct: price
        });
    }
    async handleChange(event) {
        console.log('rrr', event.target.files)
        let formData = new FormData();
        formData.append('images', event.target.files[0]);
        formData.append('width', 100);
        formData.append('height', 100);
        let rs = await fetch('https://backend.vnclean.vn/api/file-management/upload-image', {
            method: 'POST',
            headers: {
                Authorization: localStorage.getItem('userTK'),
                // 'Content-Type': 'multipart/form-data'
                "api-version": "public",
            },
            body: formData,
        })
        let data = await rs.json()
        // console.log('avatar', data.created[0].serverFileName)
        this.setState({
            logoProduct: data.created[0].url,
        });
    }
    validate = () => {
        let nameProductError = "";
        let logoProductError = "";
        let priceProductError = "";
        var { nameProduct, logoProduct, priceProduct } = this.state;
        if (!nameProduct) {
            nameProductError = 'Tên sản phẩm không được để trống!'
        }
        if (!logoProduct) {
            logoProductError = 'Hãy upload logo !'
        }
        if (!priceProduct) {
            priceProductError = 'Phần giá tiền không được để trống!'
        }
        if (nameProductError || logoProductError || priceProductError) {
            this.setState({ nameProductError, logoProductError, priceProductError });
            return false;
        }
        return true
    }
    onSubmit = async e => {
        e.preventDefault();
        e.target.reset()
        const isValid = this.validate()
        var { nameProduct, logoProduct, priceProduct } = this.state;
        if (isValid) {
            var category = {
                name: nameProduct,
                icon: logoProduct,
                price: priceProduct
            }
            await api.dataService.addNewCategoryApp(this.props.match.params.id, "3", category)

            this.setState({
                success: !this.state.success,
                nameProduct: '',
                logoProduct: '',
                priceProduct: ''
            })
            setTimeout(() => {
                this.getProductList()
            }, 500);
        }
    }
    onSubmitUpdate = async e => {
        e.preventDefault();
        e.target.reset()
        const isValid = this.validate()
        var { idProduct, nameProduct, logoProduct, priceProduct } = this.state;
        if (isValid) {
            var category = {
                name: nameProduct,
                icon: logoProduct,
                price: priceProduct
            }
            await api.dataService.updateCategoryApp(idProduct, category)

            this.setState({
                successProduct: !this.state.successProduct,
                idProduct: '',
                nameProduct: '',
                logoProduct: '',
                priceProduct: ''
            })
            setTimeout(() => {
                this.getProductList()
            }, 500);
        }
    }
    handeleDeleteCategory(data) {
        if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
            api.dataService.deleteCategoryApp(data)
            setTimeout(() => {
                this.getProductList()
            }, 500);
        }
    }
    updateAreaCategory = async (e) => {
        e.preventDefault();
        e.target.reset();
        var { name } = this.state;
        var category = {
            name: name
        }
        await api.dataService.updateCategoryApp(this.props.match.params.id, category)

        this.setState({
            name: '',
        })
        setTimeout(() => {
            window.location.reload()
        }, 500);
    }
    async getAreaService() {
        var id = this.props.match.params.id
        var result = await api.dataService.getCategoryApp("", "2")
        this.setState({
            areaServiceCategory: result.data.data
        })
    }
    async getCategoryAppId() {
        var result = await api.dataService.getCategoryAppId(this.props.match.params.id)
        this.setState({
            id: result.data.data[0].id,
            name: result.data.data[0].name,
        })
    }
    formatCurrency(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    }
    render() {
        var { appProductList, areaServiceCategory, id, name, nameError, idProduct, nameProduct, nameProductError, logoProduct, logoProductError, priceProduct, priceProductError } = this.state
        return (
            <div className="animated fadeIn">
                <Row>
                    {this.props.match.params.id ?
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <i className="fa fa-align-justify"></i> Cập nhật danh mục
                            </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={4}>
                                            <Form onSubmit={this.updateAreaCategory}>
                                                <FormGroup>
                                                    <Label>Tên danh mục miền</Label>
                                                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange}></Input>
                                                </FormGroup>
                                                <Button type="submit" color="success" className="float-right" style={{ margin: ' 10px 0' }}>Lưu</Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        : null}
                    {this.props.match.params.id ?
                        <Col xs={12} >
                            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm sản phẩm vào: {name}</Button>
                        </Col>
                        : null}
                    <br />
                    <br />
                    <br />
                    <Col xs={12}>
                        <Card>
                            <CardHeader>
                                {/* <i className="fa fa-align-justify"></i> {listCategories.length > 0 ? `Danh mục sản phẩm (${listCategories.length} mục)` : 'Đang xử lý ...'} */}
                            </CardHeader>
                            <CardBody>
                                {
                                    appProductList.length > 0 ?
                                        <Table responsive bordered>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Id</th>
                                                    <th className="text-center">Logo</th>
                                                    <th className="text-center">Tên Sản Phẩm</th>
                                                    <th className="text-center">Giá tiền</th>
                                                    <th className="text-center">Thuộc danh mục dịch vụ theo miền</th>
                                                    <th className="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {appProductList.map((productList, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="text-center">{productList.id}</td>
                                                            <td className="text-center">{productList.icon ? <img src={productList.icon} style={{ width: "60px", height: "60px" }}></img> : null}</td>
                                                            <td className="text-center">{productList.name}</td>
                                                            <td className="text-center">{this.formatCurrency(productList.price)}</td>
                                                            <td className="text-center">
                                                            {areaServiceCategory ? areaServiceCategory.find(x => x.id === productList.parentid).name : null}
                                                            </td>
                                                            <td className="text-center" style={{ width: "20%" }}>
                                                                <Row>
                                                                    <Col>
                                                                        <Button outline color="success" size="sm" block onClick={() => this.toggleSuccessProduct(productList.id, productList.icon, productList.name, productList.price)}>Chỉnh sửa</Button>
                                                                    </Col>
                                                                    <Col>
                                                                        <Button size="sm" outline color="danger" size="sm" block onClick={() => this.handeleDeleteCategory(productList.id)}>Xóa</Button>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </Table>
                                        : <Alert color="warning" className="center">
                                            Không có dữ liệu !
                                    </Alert>
                                }

                            </CardBody>
                        </Card>
                    </Col>
                    <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
                        className={'modal-success ' + this.props.className}>
                        <ModalHeader toggle={this.toggleSuccess}>Thêm sản phẩm vào: {name}</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={this.onSubmit}>
                                <img style={{ width: 'auto', height: '191px', margin: 'auto' }} src={logoProduct} alt='' />
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <Input type="file" id="logo" name="logo" onChange={event => this.handleChange(event)} >

                                            </Input>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Tên sản phẩm</InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="nameProduct" name="nameProduct" value={nameProduct} onChange={this.onChange.bind(this)} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {nameProductError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameProductError}</i> </p> : ''}
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Giá tiền</InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="priceProduct" name="priceProduct" value={priceProduct} onChange={this.onChange.bind(this)} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {priceProductError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{priceProductError}</i> </p> : ''}
                                </FormGroup>
                                <Button type="submit" size="sm" color="primary">Thêm sản phẩm</Button>
                            </Form>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.successProduct} toggle={this.toggleSuccessProduct}
                        className={'modal-success ' + this.props.className}>
                        <ModalHeader toggle={this.toggleSuccessProduct}>Cập nhập sản phẩm: {nameProduct}</ModalHeader>
                        <ModalBody>
                            <Form onSubmit={this.onSubmitUpdate}>
                                <img style={{ width: 'auto', height: '191px', margin: 'auto' }} src={logoProduct} alt='' />
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <Input type="file" id="logo" name="logo" onChange={event => this.handleChange(event)} >

                                            </Input>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Tên sản phẩm</InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="nameProduct" name="nameProduct" value={nameProduct} onChange={this.onChange.bind(this)} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {nameProductError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameProductError}</i> </p> : ''}
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>Giá tiền</InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" id="priceProduct" name="priceProduct" value={priceProduct} onChange={this.onChange.bind(this)} />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText></InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {priceProductError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{priceProductError}</i> </p> : ''}
                                </FormGroup>
                                <Button type="submit" size="sm" color="primary">Cập nhập</Button>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Row>
            </div>
        )
    }
}
export default AppProductList;