import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
import navigation2 from '../../_nav2';
import navigation3 from '../../_nav3';

// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleId: ''
    }
    let token = localStorage.getItem('RFTK-GK');
    if (!token) {
      this.props.history.push('/login')
    }
  }


  componentDidMount() {
    var roleId = localStorage.getItem('roleId') ? localStorage.getItem('roleId').split(',').map(v => Number(v)) : '';
    let maxRole = Math.max(...roleId);
    var roleIds = maxRole ? maxRole : ''
    this.setState({ roleId: roleIds })
  }
  // componentWillMount() {

  //   this.checkStatusLogin()
  // }
  // async checkStatusLogin() {
  //   let token = localStorage.getItem('RFTK-GK');
  //   if (!token) {
  //     this.props.history.push('/login')
  //   }
  // }

  NavWithRole = (roleId)=>{
    switch(roleId){
      case 6 || 7:
      return navigation
      case 2:
      return navigation3
      return
      default:
      return navigation2
    }
  }
  render() {
    var { roleId } = this.state
    // console.log('roleId', roleId[0])

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            {<AppSidebarNav navConfig={this.NavWithRole(roleId)} {...this.props} />}
            <AppSidebarFooter />
            {/* <AppSidebarMinimizer /> */}
          </AppSidebar>
          <main className="main">
            <AppBreadcrumb appRoutes={routes} />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to="/login" />



              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
