
export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'icon-speedometer',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    // {
    //   title: true,
    //   name: 'Theme',
    //   wrapper: {            // optional wrapper object
    //     element: '',        // required valid HTML5 element tag
    //     attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
    //   },
    //   class: ''             // optional class names space delimited list for title item ex: "text-center"
    // },
    // {
    //   name: 'Colors',
    //   url: '/theme/colors',
    //   icon: 'icon-drop',
    // },
    // {
    //   name: 'Typography',
    //   url: '/theme/typography',
    //   icon: 'icon-pencil',
    // },
    {
      title: true,
      name: 'VKShare',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Người dùng VKShare',
      url: '/quanlynguoidung',
      icon: 'cui-user-female',
      children: [
        {
          name: 'Danh sách đã duyệt',
          url: '/quanlynguoidung/nguoidungvoucher',
          icon: 'cui-book',
        },
        {
          name: 'Danh sách chờ duyệt',
          url: '/quanlynguoidung/nguoiduyetvoucher',
          icon: 'cui-bookmark',
        },
        {
          name: 'Thống kê thu nhập',
          url: '/quanlynguoidung/thongkethunhap',
          icon: 'cui-aperture',
        },
      ],
    },
    {
      name: 'Quản lý sản phẩm ',
      url: '/quanlysanpham',
      icon: 'cui-globe',
      children: [
        {
          name: 'Danh sách sản phẩm',
          url: '/quanlysanpham/danhsachsanpham',
          icon: 'cui-cart',
        },
        {
          name: 'Danh mục sản phẩm',
          url: '/quanlysanpham/danhmucsanpham',
          icon: 'cui-brush',
        },
        {
          name: 'Giá sản phẩm',
          url: '/quanlysanpham/giasanpham',
          icon: 'cui-british-pound ',
        },

      ],
    },
    {
      name: 'Quản lý cửa hàng',
      url: '/quanlycuahang',
      icon: 'icon-basket-loaded',
      children: [
        {
          name: 'Danh sách cửa hàng',
          url: '/quanlycuahang/stores',
          icon: 'cui-social-spotify',

        },
        {
          name: 'Khu vực cửa hàng',
          url: '/quanlycuahang/storesarea',
          icon: 'cui-clipboard',

        },
        {
          name: 'Quản lý xưởng',
          url: '/quanlycuahang/storepartner',
          icon: 'cui-basket-loaded',

        },
      ]
    },
    {
      name: 'Quản lý Voucher',
      url: '/voucher',
      icon: 'cui-layers',
      children: [
        {
          name: 'Danh sách voucher',
          url: '/voucher/danhsachvoucher',
          icon: 'cui-note',
        },
        {
          name: 'Voucher đối tác',
          url: '/voucher/partner',
          icon: 'cui-note',
        },
        {
          name: 'Danh sách khách hàng sử dụng voucher đối tác',
          url: '/voucher/list-customer',
          icon: 'cui-note',
        }
      ],
    },
    {
      name: 'Quản lý đối tác',
      url: '/quanlydoitac',
      icon: 'icon-basket-loaded',
      children: [
        {
          name: 'Danh sách đối tác',
          url: '/quanlydoitac/partner',
          icon: 'cui-social-spotify'
        }
      ]
    },
    {
    name: 'Danh sách sản phẩm trong App',
    url: '/danhmucsanphamtheoapp',
    icon: 'icon-basket-loaded',
    children: [
      {
        name: 'Danh mục sản phẩm',
        url: '/danhmucsanphamtheoapp/danhmucsanpham',
        icon: 'cui-social-spotify'
      },
      {
        name: 'Danh mục miền',
        url: '/danhmucsanphamtheoapp/danhmucmien',
        icon: 'cui-social-spotify'
      },
      {
        name: 'Danh mục dịch vụ theo miền',
        url: '/danhmucsanphamtheoapp/danhmucdichvu',
        icon: 'cui-social-spotify'
      },
      {
        name: 'Danh sách sản phẩm',
        url: '/danhmucsanphamtheoapp/danhsachsanpham',
        icon: 'cui-social-spotify'
      }
    ]
    },
    {
      name: 'Hóa đơn',
      url: '/bill',
      icon: 'cui-layers',
      children: [
        {
          name: 'Danh sách hóa đơn',
          url: '/bill/view',
          icon: 'cui-note',
        },
        {
          name: 'Hóa đơn hủy',
          url: '/bill/bill-cancel',
          icon: 'cui-note',
        },
      ],
    },
    {
      name: 'Thông tin người dùng',
      url: '/icons',
      icon: 'icon-people',
      children: [
        {
          name: 'Hồ sơ cá nhân',
          url: '/user/profile',
          icon: 'icon-star',
          badge: {
            variant: 'info',
            text: 'NEW',
          },
        },
      ],
    },
    {
      name: 'Xếp hạng khách hàng',
      url: '/xephangkhachhang',
      icon: 'icon-chart',
    },
    {
      name: 'Báo cáo',
      url: '/report',
      icon: 'cui-graph',
      children: [
        {
          name: 'Thống kê khách hàng sử dụng dịch vụ',
          url: '/report/CustomerUseService',
          icon: 'icon-bell',
        },
        {
          name: 'Thống kê sử dụng mã đối tác',
          url: '/report/voucherPartner',
          icon: 'icon-bell',
        },
        {
          name: 'Báo cáo cơ cấu doanh thu theo sản phẩm',
          url: '/report/revenue-structure',
          icon: 'icon-bell',
        },
        {
          name: 'Báo cáo nợ',
          url: '/report/debt',
          icon: 'icon-bell',
        },
        {
          name: 'Thống kê Email',
          url: '/report/email',
          icon: 'icon-bell',
        },
        {
          name: 'Thống kê SMS',
          url: '/report/sms',
          icon: 'icon-bell',
        },
        {
          name: 'Lượng hóa đơn tự xử lý',
          url: '/report/finish-work',
          icon: 'icon-bell',
        },
        {
          name: 'Doanh số nhân viên',
          url: '/report/turnover',
          icon: 'icon-directions',
        },
        {
          name: 'Doanh số chuyển xưởng',
          url: '/report/partner-store/turnover',
          icon: 'cui-bolt',
        },
        {
          name: 'Doanh số cửa hàng',
          url: '/report/store/turnover',
          icon: 'cui-bolt',
        },
        {
          name: 'Danh sách tăng ca',
          url: '/report/overtime',
          icon: 'cui-briefcase',
        },
        {
          name: 'Đánh giá người dùng',
          url: '/report/rating',
          icon: 'cui-briefcase',
        },
        {
          name: 'Thống kê nhận xét',
          url: '/report/ratingStar',
          icon: 'cui-briefcase',
        },
        {
          name: 'Thống kê kiểu đánh giá',
          url: '/report/ratingType',
          icon: 'cui-briefcase',
        },
        // {
        //   name: 'Modals',
        //   url: '/report/modals',
        //   icon: 'icon-bell',
        // },
      ],
    },

    {
      name: 'Duyệt giá sản phẩm',
      url: '/specific',
      icon: 'cui-screen-desktop',
      children: [
        {
          name: 'Sản phẩm đã duyệt',
          url: '/specific/specific-approved',
          icon: 'cui-action-redo',
        },
        {
          name: 'Sản phẩm chờ duyệt',
          url: '/specific/specific-pending',
          icon: 'icon-bell',
        },
      ],
    },

    {
      name: 'Đánh giá nhận xét',
      url: '/rating-reviews',
      icon: 'cui-layers',
      children: [
        {
          name: 'Danh sách đánh giá',
          url: '/rating-reviews/views',
          icon: 'cui-note',
        },
      ],
    },
    {
      name: 'Cấu hình hệ thống',
      url: '/config',
      icon: 'cui-list',
      children: [
        {
          name: 'Cấu hình hệ thống',
          url: '/config/configmanagament',
          icon: 'cui-options',
        },
      ],
    },
    {
      name: 'Cấu hình Banner APP',
      url: '/cauhinhbannerapp',
      icon: 'icon-camera',
    },
    {
      name: 'Thay đổi mật khẩu',
      url: '/password',
      icon: 'cui-settings',
      children: [

        {
          name: 'Mật khẩu người dùng',
          url: '/password/changepassword',
          icon: 'cui-speedometer',
        },
        {
          name: 'Mật khẩu cửa hàng',
          url: '/password/changepasswordstore',
          icon: 'cui-pencil',
        },
        {
          name: 'Khôi phục mật khẩu',
          url: '/password/resetpassword',
          icon: 'cui-people',
        },

      ],
    },
    // {
    //   name: 'Widgets',
    //   url: '/widgets',
    //   icon: 'icon-calculator',
    //   badge: {
    //     variant: 'info',
    //     text: 'NEW',
    //   },
    // },
    {
      divider: true,
    },
    // {
    //   title: true,
    //   name: 'ĐĂNG NHẬP VÀ BẢO MẬT',
    // },
    // {
    //   name: 'ĐĂNG NHẬP',
    //   url: '/pages',
    //   icon: 'cui-user',
    //   children: [
    //     {
    //       name: 'Login',
    //       url: '/login',
    //       icon: 'cui-user',
    //     },

    //   ],
    // },
    // {
    //   name: 'Wash Admin',
    //   url: '',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Download CoreUI',
    //   url: 'http://coreui.io/react/',
    //   icon: 'icon-cloud-download',
    //   class: 'mt-auto',
    //   variant: 'success',
    // },
    // {
    //   name: 'Try CoreUI PRO',
    //   url: 'http://coreui.io/pro/react/',
    //   icon: 'icon-layers',
    //   variant: 'danger',
    // },
  ],
};
