var initialState = {
  storepartners: []
}

const listStorePartners = (state = initialState, action) => {
  let storepartners = [...state.storepartners];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_STOREPARTNERS':
      // console.log({ action })
      return Object.assign({}, state, { storepartners: action.storepartner });
    case 'ADD_STOREPARTNER':
      // console.log({ action })
      storepartners.push(action.storepartner);
      return { ...state, storepartners };
    case 'UPDATE_STOREPARTNER':
      // console.log({ actionUpdate: action })
      id = action.storepartner.id;
      index = findIndex(state, { id });
      if (index == -1) {
        storepartners.push(action.storepartner);
      } else {
        storepartners[index] = action.storepartner
      }

      // console.log('index', index)
      // users[index] = action.room;
      // console.log('storepartners', storepartners)
      // return { ...state, users };
      return { ...state, storepartners };
    case 'DELETE_STOREPARTNER':
      // console.log({ action })
      index = findIndex(state, action.id);

      storepartners.splice(index, 1);
      return { ...state, storepartners };
    default: return state;
  }
}

var findIndex = (storepartners, id) => {
  let result = -1;
  storepartners.storepartners.forEach((storepartner, index) => {
    if (storepartner.id === id) {
      result = index;
    }
  });

  return result;
}

export default listStorePartners;
