import React, { Component } from 'react';
import {
  Col, Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CustomInput,

} from 'reactstrap';
import moment from 'moment'
import qs from 'query-string';
import { api } from '../../../views'
import '../../../index.css'
class PushNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      content: '',
      info:'',
      warning:'',
      dangerous:'',
      selected:'info'
    };
  }

 
  componentDidMount() {
  }
  
  onChange = async (event) => {
    console.log(event.target)
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
 
  async actPushNotice() {
    const {  content, title,selected } = this.state;
      var notice = {
        title: title,
        content: content,
      level:selected
      }
      var result = await api.dataService.actPushNotice(notice)
      if(result.status === 200){
        api.api.showMessage(result.data.message,'Thông báo')
        this.setState({title:'',content:'', selected:''})
      }
        
  }
  render() {
    console.log('anh',moment().endOf('month').fromNow())
    const {  content, title,info,warning,dangerous } = this.state;
    return (
      <div className="customerreviews">
        <Alert color="danger" className="center">
         Gửi thông báo
      </Alert>
        <Form lg>
          <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold">Tiêu đề : </Label>
            <Col sm={9}>
              <Input type="text" name="title" value={title} id="title" min={0} step={1} onChange={this.onChange.bind(this)} placeholder="Nhập tiêu đề ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleText" sm={3} className="bold">Nội dung :</Label>
            <Col sm={9}>
              <Input type="textarea" style={{maxHeight:'100px'}} name="content" value={content} id="content" onChange={this.onChange.bind(this)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleCheckbox" sm={3} className="bold">Level :</Label>
            <Col sm={9}>
            <div>
            <CustomInput type="radio" id="info"  
             checked={this.state.selected === 'info'} value="info"  
             onChange={(e) => this.setState({ selected: e.target.value })}  
            label="Thông báo" />
            <CustomInput type="radio" id="warning" 
           checked={this.state.selected === 'warning'} value="warning"
             onChange={(e) => this.setState({ selected: e.target.value })}  label="Cảnh báo" />
            <CustomInput type="radio" id="dangerous"
             checked={this.state.selected === 'dangerous'} value="dangerous" 
             onChange={(e) => {
               console.log(e.target.value)
               this.setState({ selected: e.target.value })}}  label="Nguy hiểm"  />
          </div>
            </Col>


          </FormGroup>



          <FormGroup check row>
            <Col className="right">
              <Button className="right" color="success" onClick={this.actPushNotice.bind(this)}>Gửi</Button>
            </Col>
          </FormGroup>
        </Form>

      </div>
    )
  }
}

export default PushNotice;

