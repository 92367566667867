var initialState = {
  stores: []
}

const listStores = (state = initialState, action) => {
  let stores = [...state.stores];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_STORES':
      // console.log({ action })
      return Object.assign({}, state, { stores: action.storea });
    case 'ADD_STORE':
      // console.log({ action })
      stores.push(action.storea);
      return { ...state, stores };
    case 'UPDATE_STORE':
      // console.log({ actionUpdate: action })
      id = action.storea.id;
      index = findIndex(state, { id });
      if (index == -1) {
        stores.push(action.storea);
      } else {
        stores[index] = action.storea
      }
      return { ...state, stores };
    case 'DELETE_STORES':
      index = findIndex(state, action.id);

      stores.splice(index, 1);
      return { ...state, stores };
    default: return state;
  }
}

var findIndex = (stores, id) => {
  let result = -1;
  stores.stores.forEach((storea, index) => {
    if (storea.id === id) {
      result = index;
    }
  });

  return result;
}

export default listStores;
