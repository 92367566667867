import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Alert, Modal,
  ModalBody,
  Form,
  FormGroup,
  Row, Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText, Label,
  ModalHeader, Pagination, PaginationItem, PaginationLink, Table
} from 'reactstrap';
import { api } from '../..'
import { connect } from "react-redux";
import moment from 'moment'
import qs from 'query-string'
import '../../../index.css'

function UserRow(props) {
  const voucher = props.voucher
  const userLink = `#/voucher/danhsachvoucher/${voucher.id}`
  function formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  async function handleDeleteProduct() {

  }
  return (
    <tr >
      <th scope="row"><a href={userLink}>{voucher.id}</a></th>
      <td style={{ width: '150px' }}>
        <div className="small ">
          <span className="bold">Mã voucher</span> | {voucher.code}
        </div>
        <div className="small ">
          <span className="bold">Mô tả</span> | {voucher.description}
        </div>
        <div className="small ">
          <span className="bold">Cửa hàng</span> | {JSON.parse(voucher.storeInfo)['storeName']}
        </div>
        <a href={userLink}>Xem chi tiết</a></td>
      <td>{moment(voucher.from).format('DD/MM/YYYY')}</td>
      <td>{moment(voucher.to).format('DD/MM/YYYY')}</td>
      <td>{voucher.forCustomerPercent}  <i style={{ float: 'right' }} className="fa fa-percent fa"></i></td>
      <td>{voucher.forVoucherUserPercent} <i style={{ float: 'right' }} className="fa fa-percent fa"></i></td>
      <td>{formatCurrency(voucher.forCustomerMoney)} <span style={{ float: 'right' }}><b>VNĐ</b> </span></td>
      <td>{formatCurrency(voucher.forVoucherUserMoney)}<span style={{ float: 'right' }}><b>VNĐ</b></span></td>
      <td>{formatCurrency(voucher.minBillMoney)}<span style={{ float: 'right' }}><b>VNĐ</b></span></td>
    </tr >
  )
}

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      code: '',
      codeError: '',
      fromDate: '',
      fromDateError: '',
      toDate: '',
      toDateError: '',
      forCustomerPercent: '',
      forCustomerPercentError: '',
      forVoucherUserPercent: '',
      forVoucherUserPercentError: '',
      forCustomerMoney: '',
      forCustomerMoneyError: '',
      forVoucherUserMoney: '',
      forVoucherUserMoneyError: '',
      minBillMoney: '',
      minBillMoneyError: '',
      description: '',
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  validate = () => {
    let codeError = "";
    let fromDateError = "";
    let toDateError = "";
    let forCustomerPercentError = "";
    let forVoucherUserPercentError = "";
    let forCustomerMoneyError = "";
    let forVoucherUserMoneyError = "";
    let minBillMoneyError = "";
    var { code, fromDate, toDate, minBillMoney, forVoucherUserMoney, forCustomerMoney, forVoucherUserPercent, forCustomerPercent } = this.state;
    if (!code) {
      codeError = 'Mã code không được để trống !'
    }

    if (!fromDate) {
      fromDateError = 'Bạn chưa chọn ngày !'
    }
    if (!toDate) {
      toDateError = 'Bạn chưa chọn ngày !'
    }
    if (!minBillMoney) {
      minBillMoneyError = 'Trường này không được để trống !'
    }
    if (!forVoucherUserMoney) {
      forVoucherUserMoneyError = 'Trường này không được để trống !'
    }
    if (!forCustomerMoney) {
      forCustomerMoneyError = 'Trường này không được để trống !';
    }
    if (!forVoucherUserPercent) {
      forVoucherUserPercentError = 'Trường này không được để trống !';
    }
    if (!forCustomerPercent) {
      forCustomerPercentError = 'Trường này không được để trống !';
    }
    if (forCustomerMoneyError || forCustomerPercentError || forVoucherUserPercentError || codeError || fromDateError || toDateError || minBillMoneyError || forVoucherUserMoneyError) {
      this.setState({ forCustomerMoneyError, forCustomerPercentError, forVoucherUserPercentError, codeError, fromDateError, toDateError, minBillMoneyError, forVoucherUserMoneyError });
      return false;
    }
    return true
  }
  async handleClick(event) {
    let search = '?'
    let code = qs.parse(this.props.location.search).code;
    let where = {};

    if (code) {
      where.code = code
      search += '&code=' + code
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actFetchVouchers(where)
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    this.actFetchVouchers()
  }

  async actFetchVouchers(where) {

    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchVouchersRequest(skip, limit, '', where, '')
    let count = result.count;


    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ next, prev, count, currentPage, last: maxPage || 1, pagesNumber });

  }

  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { code, fromDate, description, toDate, minBillMoney, forVoucherUserMoney, forCustomerMoney, forVoucherUserPercent, forCustomerPercent } = this.state;
    if (isValid) {
      var voucher = {
        code: code,
        description: description,
        from: moment(fromDate).valueOf(),
        to: moment(toDate).valueOf(),
        minBillMoney: minBillMoney,
        forVoucherUserMoney: forVoucherUserMoney,
        forCustomerMoney: forCustomerMoney,
        forVoucherUserPercent: forVoucherUserPercent,
        forCustomerPercent: forCustomerPercent,
        storeInfo: JSON.stringify({storeId : 0, storeName: `Vu khuc`})
      }
      var result = await api.dataService.actAddVoucherRequest(voucher)
      api.dataService.actFetchVouchersRequest('', 100, '')

      this.setState({
        success: !this.state.success,
      })
    }

  }

  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { code } = this.state;
    let where = {};

    if (code) {
      where.code = code
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&code=' + code)
    this.actFetchVouchers(where)


  } 
  render() {
    var { code, codeError, count, description, forCustomerMoneyError, forCustomerPercentError, forVoucherUserMoneyError, forVoucherUserPercentError, fromDateError, minBillMoneyError, toDateError,
      fromDate, toDate, minBillMoney, currentPage, prev, next, pagesNumber, last, forVoucherUserMoney, forCustomerMoney, forVoucherUserPercent, forCustomerPercent } = this.state;
    var { listVouchers } = this.props;
    listVouchers = this.props.listVouchers.vouchers
    
    console.log('xxx', listVouchers)
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>

                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã</Label>
                        <Input type="text" id="code" name="code" value={code} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>

                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col xs={12} >
            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm voucher</Button>
          </Col>
          <br />
          <br />
          <br />
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {listVouchers.length > 0 ? `Danh sách Voucher (${count} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {listVouchers.length > 0 ?
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th className="text-center font-sm">ID</th>
                        <th className="text-center font-sm">Thông tin mã ưu đãi</th>
                        <th className="text-center font-sm">Thời gian bắt đầu áp dụng</th>
                        <th className="text-center font-sm">Thời gian kết thúc áp dụng</th>
                        <th className="text-center font-sm">Phần trăm giảm giá cho khách hàng</th>
                        <th className="text-center font-sm">Phần trăm cho người phát voucher </th>
                        <th className="text-center font-sm">Tiền giảm giá cho khách</th>
                        <th className="text-center font-sm">Tiền dành cho người phát voucher</th>
                        <th className="text-center font-sm">Số tiền tối thiểu áp dụng voucher</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listVouchers.map((voucher, index) =>
                        <UserRow key={index} voucher={voucher} />
                      )}
                    </tbody>
                  </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}
                <div style={{ display: 'table', margin: '0 auto' }}>
                  {pagesNumber.length == 1 ? '' : <Pagination >
                    <PaginationItem>
                      {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                      }
                    </PaginationItem>
                    <PaginationItem>
                      {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                      }
                    </PaginationItem>
                    {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                      return (<Pagination key={index}>
                        <PaginationItem active={currentPage == (pageNumber)} >
                          {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            {pageNumber}
                          </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                              ...
                          </PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>)

                    })
                    }

                    <PaginationItem>
                      {
                        next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                      }
                    </PaginationItem>

                    <PaginationItem>
                      {
                        currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>}

                </div>
              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm voucher</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Mã voucher</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="code" name="code" value={code} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {codeError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{codeError}</i> </p> : ''}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Mô tả</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Ngày bắt đầu</InputGroupText>
                    </InputGroupAddon>
                    <Input type="date" id="fromDate" name="fromDate" value={fromDate} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {fromDateError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{fromDateError}</i> </p> : ''}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Ngày kết thúc</InputGroupText>
                    </InputGroupAddon>
                    <Input type="date" id="toDate" name="toDate" value={toDate} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {toDateError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{toDateError}</i> </p> : ''}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Phần trăm giảm giá khách hàng</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="forCustomerPercent" name="forCustomerPercent" value={forCustomerPercent} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-percent fa"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {forCustomerPercentError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{forCustomerPercentError}</i> </p> : ''}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Phần trăm cho người phát voucher</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="forVoucherUserPercent" name="forVoucherUserPercent" value={forVoucherUserPercent} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-percent fa"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {forVoucherUserPercentError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{forVoucherUserPercentError}</i> </p> : ''}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tiền giảm giá cho khách</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="forCustomerMoney" name="forCustomerMoney" value={forCustomerMoney} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><b>VNĐ</b></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {forCustomerMoneyError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{forCustomerMoneyError}</i> </p> : ''}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tiền dành cho người phát voucher</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="forVoucherUserMoney" name="forVoucherUserMoney" value={forVoucherUserMoney} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><b>VNĐ</b></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {forVoucherUserMoneyError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{forVoucherUserMoneyError}</i> </p> : ''}
                </FormGroup>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Số tiền tối thiểu áp dụng voucher</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="minBillMoney" name="minBillMoney" value={minBillMoney} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><b>VNĐ</b></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {minBillMoneyError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{minBillMoneyError}</i> </p> : ''}
                </FormGroup>
                <FormGroup className="form-actions text-right">
                  <Button type="submit" size="sm" color="primary">Thêm voucher</Button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listVouchers: state.listVouchers,
  }
}
export default connect(mapStateToProps)(Vouchers);