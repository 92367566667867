import React, { Component } from 'react';
import {
  Button, Row, Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Table, Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input, Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader
} from 'reactstrap';
import { api } from '../../../views'
import { connect } from "react-redux";
import { AppSwitch } from '@coreui/react'
import qs from 'query-string'
import '../../../index.css'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
import province from '../../../assets/DataMapVN/province';
import district from '../../../assets/DataMapVN/district';

const provincelist = province.RECORDS;
const districtlistAll = district.RECORDS;

function StoreRow(props) {
  const storea = props.storea
  console.log('111', storea)
  var actFetchStore = props.actFetchStore
  // console.log('bbb', storea)
  const where = props.where
  const storeLink = `#/quanlycuahang/stores/${storea.id}`
  const storeUserLink = `#/quanlycuahang/storesuser/${storea.name}/${storea.id}`
  async function ChnageUserLocker() {

    if (storea.locked === true) {
      var store = {
        id: storea.id,
        locked: false,
      }
      var result = await api.dataService.actUpdateStoreRequest(store);
      if (result.status === 200) {
        actFetchStore(where)
      }
      else {
        api.api.showMessage('Lỗi hệ thống ! Xin thử lại sau .')
      }
    }
    else {
      var store = {
        id: storea.id,
        locked: true,
      }
      var result = await api.dataService.actUpdateStoreRequest(store);
      if (result.status === 200) {
        actFetchStore(where)
      }
      else {
        api.api.showMessage('Lỗi hệ thống ! Xin thử lại sau .')
      }
      console.log('111', result)

    }

  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={storeLink}>{storea.id}</a></th>
      <td className="text-left">
        <div className="small ">
          <span className="bold">Tên cửa hàng</span> |  <a href={storeLink}>{storea.name}</a>
        </div>

        <div className="small ">
          <span className="bold">Tài khoản</span> | {storea.account}
        </div>
      </td>
      <td className="text-left">
        <div className="small ">
          <span className="bold">SĐT</span> | {storea.phone}
        </div>
        <div className="small ">
          <span className="bold">Địa chỉ</span> | {storea.address}
        </div>

      </td>
      <td className="text-center">  <AppSwitch className={'mx-1'} id="locked" name="locked" checked={storea.locked ? true : false} onChange={ChnageUserLocker} color={'primary'} label /></td>
      <td className="text-center">{storea.storeAreaId.name}</td>
      <td className="text-center"> <Button href={storeUserLink} color="info" size="sm" block>Xem</Button></td>
    </tr >
  )
}

class Stores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      account: '',
      password: '',
      email: '',
      accountError: '',
      emailError: '',
      nameError: '',
      phoneError: '',
      descriptionError: '',
      addressError: '',
      storeAreaIdError: '',
      name: '',
      phone: '',
      description: '',
      address: '',
      id: '',
      storearea: '',
      storeAreaId: '',
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: [],
      where: '',
      district: '',
      city: '',
      districtError: '',
      cityError: '',
      logo: '',
      logoError: '',
      website: ''
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  validate = () => {
    let emailError = "";
    let descriptionError = "";
    let accountError = "";
    let addressError = "";
    let nameError = "";
    let storeAreaIdError = "";
    let phoneError = "";
    let districtError = "";
    let cityError = "";
    let logoError = "";
    var { account, name, email, password, address, phone, description, storeAreaId, city, district, logo } = this.state;
    const regexp = /^0\d{9,10}$/;
    if (!account) {
      accountError = 'Tên tài khoản không được để trống !'
    }
    if (!name) {
      nameError = 'Tên cửa hàng không được để trống !'
    }
    if (!address) {
      addressError = 'Trường này không được để trống !'
    }
    if (!description) {
      descriptionError = 'Trường này không được để trống !'
    }
    if (!storeAreaId) {
      storeAreaIdError = 'Vui lòng chọn khu vực của hàng !'
    }
    if (!email.includes("@")) {
      emailError = 'Email không đúng định dạng !';
    }
    if (!phone.match(regexp)) {
      phoneError = 'Số điện thoại phải bắt đầu bằng 0 và gồm 10-11 chữ số !'
    }
    if (!district) {
      districtError = 'Tên quận không được để trống !'
    }
    if (!city) {
      cityError = 'Tên tỉnh,thành phố không được để trống !'
    }
    if (!logo) {
      logoError = 'Logo không được để trống !'
    }
    if (emailError || phoneError || nameError || storeAreaIdError || addressError || descriptionError || accountError || districtError || cityError || logoError) {
      this.setState({ emailError, phoneError, nameError, storeAreaIdError, addressError, descriptionError, accountError, districtError, cityError, logoError });
      return false;
    }
    return true
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    // var { where } = this.state
    this.actGetStorearea()
    this.actFetchStore()
  }

  async actFetchStore(where) {

    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchStoresRequest(skip, limit, '', where, [{ id: 'ASC' }])
    console.log('rrr', result)
    let count = result.data.count;


    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ next, prev, count, currentPage, last: maxPage || 1, pagesNumber });
  }
  async handleClick(event) {
    let search = '?'
    let name = qs.parse(this.props.location.search).name;
    let storeAreaId = qs.parse(this.props.location.search).storeAreaId;
    let where = {};
    if (name) {
      where.name = { 'contains': name }
      search += '&name=' + name
    }

    if (storeAreaId) {
      where.storeAreaId = storeAreaId
      search += '&storeAreaId=' + storeAreaId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actFetchStore(where)
  }

  async actGetStorearea() {
    var result = await api.dataService.actFetchStoreAreaRequest('', '', '', '')
    this.setState({ storearea: result.data })
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { account, name, email, password, address, phone, description, storeAreaId, district, city,logo,website } = this.state;
    if (isValid) {
      var store = {
        logo: logo,
        account: account,
        name: name,
        email: email,
        password: password,
        address: address,
        district: district,
        city: city,
        phone: phone,
        description: description,
        website: website,
        storeAreaId: parseInt(storeAreaId)
      }
      await api.dataService.actAddStoreRequest(store)
      this.actFetchStore()
      this.setState({
        success: !this.state.success,
        logo:'',
        name: '',
        account: '',
        address: '',
        district: '',
        city: '',
        phone: '',
        description: '',
        website: ''
      })
    }
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { name, storeAreaId } = this.state;
    let where = {};

    if (name) {
      where.name = { 'contains': name }
    }
    if (storeAreaId && storeAreaId.value) {
      where.storeAreaId = storeAreaId.value
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&name=' + name + '&storeAreaId=' + (storeAreaId.value || ''))
    this.actFetchStore(where)
    // this.setState({ where })

  }
  async handleChange(event) {
    console.log('rrr', event.target.files)
    let formData = new FormData();
    formData.append('images', event.target.files[0]);
    formData.append('width', 100);
    formData.append('height', 100);
    let rs = await fetch('https://backend.vnclean.vn/api/file-management/upload-image', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('userTK'),
        // 'Content-Type': 'multipart/form-data'
        "api-version": "public",
      },
      body: formData,
    })
    let data = await rs.json()
    // console.log('avatar', data.created[0].serverFileName)
    this.setState({
      logo: data.created[0].url,
    });
  }
  render() {
    var { account, name, where, count, email, password, currentPage,
      prev, next, pagesNumber, last, address, phone, description, storearea, storeAreaId,
      accountError, addressError, descriptionError, emailError, nameError, phoneError, storeAreaIdError, city, district, cityError, districtError,logo,logoError,website } = this.state;
    var { listStores } = this.props;
    listStores = this.props.listStores.stores
    const options = storearea.length > 0 ? storearea.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    }) : ''
    let listDistrict = districtlistAll.filter(item => item.province === this.state.city)
    return (
      <Row className="animated fadeIn">
        <Col xs={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
            <CardBody>
              <Form onSubmit={this.onSubmitSearch}>
                <Row form>

                  <Col md={4}>
                    <FormGroup>
                      <Label for="exampleState">Tên</Label>
                      <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="exampleCity">Khu vực</Label>
                      <Select options={options}
                        onChange={(storeAreaId) => {
                          storeAreaId = storeAreaId || { value: '', label: '' }
                          this.setState({ storeAreaId })
                        }}
                        value={storeAreaId}
                        placeholder="Search"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup className="form-actions">
                  <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} >
          <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm mới cửa hàng</Button>
        </Col>
        <br />
        <br />
        <br />
        <Col xs={12}>
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify"></i> {listStores.length > 0 ? `Danh sách cửa hàng (${count} mục)` : 'Đang xử lý ...'}
            </CardHeader>
            <CardBody>
              {listStores.length > 0 ?
                <Table hover bordered striped responsive size="sm">
                  <thead>
                    <tr>
                      <th className="text-center">ID</th>
                      <th className="text-left">Thông tin cửa hàng</th>
                      <th className="text-left">Thông tin liên hệ</th>
                      <th className="text-center">Khóa cửa hàng</th>
                      <th className="text-center">Khu vực</th>
                      <th className="text-center">Danh sách người dùng</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listStores.map((storea, index) =>
                      <StoreRow key={index} storea={storea} where={where} actFetchStore={this.actFetchStore.bind(this)} />
                    )}

                  </tbody>
                </Table>
                : <Alert color="warning" className="center">
                  Không có dữ liệu !
      </Alert>}
              <div style={{ display: 'table', margin: '0 auto' }}>
                {pagesNumber.length == 1 ? '' : <Pagination >
                  <PaginationItem>
                    {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                    }
                  </PaginationItem>
                  <PaginationItem>
                    {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                    }
                  </PaginationItem>
                  {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                    return (<Pagination key={index}>
                      <PaginationItem active={currentPage == (pageNumber)} >
                        {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                          {pageNumber}
                        </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            ...
                          </PaginationLink>
                        }
                      </PaginationItem>
                    </Pagination>)

                  })
                  }

                  <PaginationItem>
                    {
                      next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                    }
                  </PaginationItem>

                  <PaginationItem>
                    {
                      currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                    }
                  </PaginationItem>
                </Pagination>}

              </div>
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Thêm mới cửa hàng</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
            <img style={{ width: 'auto', height: '191px', margin: 'auto' }} src= {logo} alt='' />
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Input type="file" id="logo" name="logo" onChange={event => this.handleChange(event)} >
                      
                    </Input>
                  </InputGroupAddon>
                </InputGroup>
                {logoError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{logoError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tên cửa hàng</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tài khoản</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="account" name="account" value={account} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {accountError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{accountError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mật khẩu</InputGroupText>
                  </InputGroupAddon>
                  <Input type="password" id="password" name="password" value={password} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email</InputGroupText>
                  </InputGroupAddon>
                  <Input type="email" id="email" name="email" value={email} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {emailError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{emailError}</i> </p> : ''}
              </FormGroup>

              {/* <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>FullName</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="fullname" name="fullname" value={fullname} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup> */}
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Website</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="website" name="website" value={website} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Địa chỉ</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="address" name="address" value={address} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {addressError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{addressError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Thành Phố</InputGroupText>
                  </InputGroupAddon>
                  {/* <Input type="text" id="city" name="city" value={city} onChange={this.onChange.bind(this)} /> */}
                  <Input type="select" name="city" value={city} id="city" onChange={this.onChange.bind(this)} >
                    <option value="">-- select --</option>
                    {provincelist.length > 0 ? provincelist.map(a => {
                      return (< option key={parseInt(a.id, 10)} value={a.id} > {a.name}</option>)
                    }) : ''}

                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {cityError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{cityError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Quận</InputGroupText>
                  </InputGroupAddon>
                  {/* <Input type="text" id="district" name="district" value={district} onChange={this.onChange.bind(this)} /> */}
                  <Input type="select" name="district" value={district} id="district" onChange={this.onChange.bind(this)} >
                    <option value="">-- select --</option>
                    {listDistrict.length > 0 ? listDistrict.map(a => {
                      return (< option key={parseInt(a.id, 10)} value={a.id} > {a.name}</option>)
                    }) : ''}

                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {districtError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{districtError}</i> </p> : ''}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Thêm mô tả chi tiết</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
              </FormGroup>
              {/* <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>identityCard</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="identityCard" name="identityCard" value={identityCard} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup> */}
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Số điện thoại</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="phone" name="phone" min='0' step='1' value={phone} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {phoneError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{phoneError}</i> </p> : ''}
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Khu vực</InputGroupText>
                  </InputGroupAddon>
                  <Input type="select" name="storeAreaId" value={storeAreaId} id="storeAreaId" onChange={this.onChange.bind(this)} >
                    <option value="">-- select --</option>
                    {storearea.length > 0 ? storearea.map(a => {
                      return (< option key={a.id} value={a.id} > {a.name}</option>)
                    }) : ''}

                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {storeAreaIdError ? <p style={{ color: 'red', display: 'table', margin: '0 auto' }}> <i>{storeAreaIdError}</i> </p> : ''}
              </FormGroup>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Lưu</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal>
      </Row>
    );
  }
}
const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listStores: state.listStores,
  }
}
export default connect(mapStateToProps)(Stores);


