import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Alert, Modal,
  ModalBody,
  Form,
  FormGroup,
  Row, Col,
  Input,
  ModalFooter,
  InputGroupAddon,
  InputGroupText, Label,
  ModalHeader, Pagination, PaginationItem, PaginationLink, Table
} from 'reactstrap';
import { api } from '../..'
import { connect } from "react-redux";
import moment from 'moment'
import qs from 'query-string'
import '../../../index.css'



class Bills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 1,
      first: 1,
      currentPage: 1,
      pagesNumber: [],
      token: localStorage.getItem('userTK'),
      large: false,
      id: '',
      barCode: '',
      customerPhone: '',
      where: '',
      productStore: [],
      billData: [],
      isOpenBill: false,
      fromDate: '',
      toDate: '',
      storeId: ''
    };
    this.toggleLarge = this.toggleLarge.bind(this);
  }
  async handleClick(event) {
    let search = '?'
    let barCode = qs.parse(this.props.location.search).barCode;
    let id = qs.parse(this.props.location.search).id;
    let customerPhone = qs.parse(this.props.location.search).customerPhone;
    let fromDate = qs.parse(this.props.location.search).fromDate;
    let toDate = qs.parse(this.props.location.search).toDate;
    let storeId = qs.parse(this.props.location.search).storeId;
    let where = {};
    if (barCode) {
      where.barCode = barCode
      search += '&barCode=' + barCode
    }

    if (id) {
      where.id = id
      search += '&id=' + id
    }

    if (customerPhone) {
      where.customerPhone = customerPhone
      search += '&customerPhone=' + customerPhone
    }
    if (fromDate) {
      search += '&fromDate=' + fromDate
    }
    if (storeId) {
      search += '&storeId=' + storeId
    }
    if (toDate) {
      search += '&toDate=' + toDate
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actFetchBills(where)
    this.setState({ where })
  }
  toggleLarge() {
    this.setState({
      large: !this.state.large,
    });
  }
  componentDidMount() {
    var { where } = this.state
    this.actFetchBills(where)
  }
  async actFetchBills(where) {
    var { fromDate, toDate } = this.state
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    if (!where) {
      where = {}
    }
    if (fromDate && toDate)
      where.createdAt = { '<=': moment(toDate).endOf('day').valueOf(), '>=': moment(fromDate).startOf('day').valueOf() }
    // where.storeId = JSON.parse(localStorage.getItem('userinfo')).storeId
    where.isDelete = true
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchBill(skip, limit, '', where, [{ dueDate: 'DESC' }])
    console.log('888', result)
    let count = result.count;

    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ datas: result.data, next, prev, count, currentPage, last: maxPage || 1, pagesNumber });

  }
  formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {

    e.preventDefault();
    e.target.reset()
    var { id, barCode, customerPhone, fromDate, toDate,storeId } = this.state;
    let where = {};
    if (storeId) {
      where.storeId = storeId
    }
    if (id) {
      where.id = id
    }
    if (customerPhone) {
      where.customerPhone = customerPhone
    }
    if (barCode) {
      where.barCode = barCode
    }
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&id=' + id + '&barCode=' + barCode + '&customerPhone=' + customerPhone + '&from=' + (fromDate || '') + '&to=' + (toDate || '')+ '&storeId=' + (storeId || ''))
    this.actFetchBills(where)
    this.setState({ where })
  }

  render() {
    var {storeId, datas, count, currentPage, prev, fromDate, toDate, next, pagesNumber, last, id, customerPhone, barCode } = this.state
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                  <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã cửa hàng</Label>
                        <Input type="text" id="storeId" name="storeId" value={storeId} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã hóa đơn</Label>
                        <Input type="number" min="0" step="1" id="id" name="id" value={id} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Barcode</Label>
                        <Input type="number" min="0" step="1" id="barCode" name="barCode" value={barCode} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">Số điện thoại</Label>
                        <Input type="number" min="0" step="1" id="customerPhone" name="customerPhone" value={customerPhone} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Từ ngày</Label>
                        <Input type="date" name="fromDate" id="fromDate" value={fromDate} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Đến ngày</Label>
                        <Input type="date" name="toDate" id="toDate" value={toDate} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                      </FormGroup>
                    </Col>
                    <Col md={12} className="text-right">
                      <FormGroup className="form-actions ">
                        <Button type="submit" size="md" color="primary">Tìm kiếm</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <br />
          <br />
          <br />

          <Col>
            <Alert color="warning">
              <i className="fa fa-align-justify"></i> {datas.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
            </Alert>
            <Card>
              <CardBody>
                <Table hover responsive className="table-outline mb-0 d-none d-sm-table">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center"><i className="icon-people"></i></th>
                      <th className="text-left">Thông tin khách hàng</th>
                      <th className="text-left">Ghi chú</th>
                      <th className="text-left">Thanh toán</th>
                      <th className="text-left">Đánh giá</th>
                      <th className="text-center">Trạng thái</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas !== "" ? datas.map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">
                            <span >{a.id}</span>
                          </td>
                          <td className="text-left">
                            <div className="small ">
                              <span className="bold-x">Tên</span> | {a.customerName}
                            </div>
                            <div className="small ">
                              <span className="bold-x">SĐT</span> | {a.customerPhone}
                            </div>
                            <div className="small ">
                              <span className="bold-x">Email</span> | {a.email}
                            </div>
                            <div className="small ">
                              <span className="bold-x">Địa chỉ</span> | {a.receivedAddress}
                            </div>
                          </td>
                          <td className="text-left">
                            <div className="small ">
                              <span className="bold">Mô tả</span> | {a.note}
                            </div>
                            <div className="small ">
                              <span className="bold">Ngày trả</span> |  {moment(a.dueDate).format('DD-MM-YYYY hh:mm:ss a')}
                            </div>
                            <div className="small ">
                              <span className="bold">BarCode</span> | {a.barCode}
                            </div>
                          </td>

                          <td className="text-left">
                            <div className="small ">
                              <span className="bold">Tổng tiền</span> | {this.formatCurrency(a.totalPrice)} VNĐ
                          </div>
                            <div className="small ">
                              <span className="bold">Giảm giá</span> | {this.formatCurrency(a.totalPrice - a.needPay)} VNĐ
                          </div>
                            <div className="small ">
                              <span className="bold">Thanh toán</span> | {this.formatCurrency(a.needPay)} VNĐ
                          </div>

                          </td>
                          <td className="text-left">
                            <div className="small ">
                              <span className="bold">Nhận xét</span> | {a.customerNote}
                            </div>
                            <div className="small ">
                              <span className="bold">Chất lượng</span> | {a.ratingPoint} <i className="cui-star icons bold-x "></i>
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="small ">
                              <span className="bold">{a.currentStage.name}</span>
                            </div>
                            <div className="small ">
                              <span className="bold" >{a.isPay ? 'Đã thanh toán' : 'Chưa thanh toán'}</span>
                            </div>

                          </td>

                        </tr>)
                    }) : <div className="text-center">Không có dữ liệu !</div>}


                  </tbody>
                </Table>

                <div style={{ display: 'table', margin: '0 auto' }}>
                  {pagesNumber.length == 1 ? '' : <Pagination >
                    <PaginationItem>
                      {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                      }
                    </PaginationItem>
                    <PaginationItem>
                      {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                      }
                    </PaginationItem>
                    {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                      return (<Pagination key={index}>
                        <PaginationItem active={currentPage == (pageNumber)} >
                          {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            {pageNumber}
                          </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                              ...
                          </PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>)

                    })
                    }

                    <PaginationItem>
                      {
                        next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                      }
                    </PaginationItem>

                    <PaginationItem>
                      {
                        currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>}

                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listBill: state.listBill,
  }
}
export default connect(mapStateToProps)(Bills);
