var initialState = {
  categories: []
}

const listCategories = (state = initialState, action) => {
  let categories = [...state.categories];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_CATEGORIES':
      // console.log({ action })
      return Object.assign({}, state, { categories: action.category });
    case 'ADD_CATEGORY':
      // console.log({ action })
      categories.push(action.category);
      return { ...state, categories };
    case 'UPDATE_CATEGORY':
      // console.log({ actionUpdate: action })
      id = action.category.id;
      index = findIndex(state, { id });
      if (index == -1) {
        categories.push(action.category);
      } else {
        categories[index] = action.category
      }
      // console.log('categories', categories)
      return { ...state, categories };
    case 'DELETE_CATEGORY':
      // console.log({ action })
      index = findIndex(state, action.id);

      categories.splice(index, 1);
      return { ...state, categories };
    default: return state;
  }
}

var findIndex = (categories, id) => {
  let result = -1;
  categories.categories.forEach((category, index) => {
    if (category.id === id) {
      result = index;
    }
  });

  return result;
}

export default listCategories;
