import React, { Component } from 'react';
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { connect } from "react-redux";
import { api } from '../../../views'
import logo from '../../../assets/img/brand/VKShare-01.png'
// import createHistory from "history/createBrowserHistory"
// const history = createHistory()
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            account: '',
            password: '',
            roleId: '',
            danger: false,
        }
        this.toggleDanger = this.toggleDanger.bind(this);
        let token = localStorage.getItem('RFTK-GK') || '';
        if (!token) {
            this.props.history.push('/login')
        }
    }
    toggleDanger() {
        this.setState({
            danger: !this.state.danger,
        });
    }

    handleKeyPress(event) {
        if (event.key === 'Enter') {
            this.handleLogin()
        }
    }
    async handleLogin() {
        var { password, account } = this.state
        // console.log({ googleToken: token })
        const result = await api.dataService.login(account, password, 'local')
        if (result) {
            var roleId = result.data.loginInfo.roleId
            var maxRole = Math.max(...roleId)
            // console.log('abc', result)
            if (result.status === 200) {
                console.log("@@result",result)
                localStorage.setItem('roleId', result.data.loginInfo.roleId)
                localStorage.setItem('name', result.data.loginInfo.account)
                localStorage.setItem('userId', result.data.loginInfo.userId)
                localStorage.setItem('partnerId', result.data.loginInfo.partnerId)
                localStorage.setItem('loginInfo', JSON.stringify(result.data.loginInfo))

                localStorage.setItem('avatar', result.data.loginInfo.avatar)
                this.processLoginResult(result);
                this.props.history.push('/dashboard');
            }
            else api.api.showMessage('Sai thông tin đăng nhập ! Vui lòng kiểm tra lại !', 'Warning')

        }
        else api.api.showMessage('Sai thông tin đăng nhập ! Vui lòng kiểm tra lại !', 'Warning')

    }
    componentWillMount() {
        this.checkStatusLogin()
    }
    async checkStatusLogin() {
        let token = await localStorage.getItem('RFTK-GK');
        if (token) {
            let result = await api.dataService.login('', '', 'token')
            // console.log({ result })
            if (result.status !== 200) return console.log(result.statusText)
            if (result.data.loginInfo.token)
                localStorage.setItem('RFTK-GK', result.data.loginInfo.token)
            api.api.setUserToken(result.data.loginInfo.token)
            api.api.setUserInfo(result.data.loginInfo)
        }
    }
    processLoginResult(result) {
        if (result.data.loginInfo.token)
            localStorage.setItem('RFTK-GK', result.data.loginInfo.token)
        api.api.setUserToken(result.data.loginInfo.token)
        api.api.setUserInfo(result.data.loginInfo)
        // window.location.reload()
        // this.setState({
        //   modalLogin: !this.state.modalLogin
        // })
    }
    onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        var { password, account, roleId } = this.state
        console.log('uuu', roleId)
        return (
            <div className="app flex-row align-items-center" >
                <Container>
                    <Row className="justify-content-center">
                        <Col md="8">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody style={{ backgroundColor: 'chocolate' }}>
                                        <img src={logo} alt="Vkshare" style={{ width: '100px', height: '100px', display: 'table', margin: '0 auto' }} />
                                        <h1 style={{ display: 'table', margin: '0 auto', padding: '50px 0' }}>Đăng nhập</h1>
                                        {/* <p className="text-muted">Sign In to your account</p> */}
                                        <InputGroup className="mb-3">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-user"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="text" placeholder="Username" name="account" value={account} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.onChange.bind(this)} />
                                        </InputGroup>
                                        <InputGroup className="mb-4">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="icon-lock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="password" placeholder="Password" name="password" value={password} onKeyPress={this.handleKeyPress.bind(this)} onChange={this.onChange.bind(this)} />
                                        </InputGroup>
                                        <Row>
                                            <Col xs="6">
                                                <Button color="primary" className="px-4" onClick={this.handleLogin.bind(this)}>Đăng nhập</Button>
                                            </Col>
                                            <Col xs="6" className="text-right">
                                                <Button color="link" onClick={this.toggleDanger} className="px-0">Quên mật khẩu?</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.danger} toggle={this.toggleDanger}
                        className={'modal-danger ' + this.props.className}>
                        <ModalHeader toggle={this.toggleDanger}>Thông báo</ModalHeader>
                        <ModalBody>
                            Vui lòng liên hệ quản trị viên hoặc gọi điện thoại tới số hotline để được hỗ trợ:
                            <br />
                            <span style={{ color: 'red' }}> Hotline: <a href="tel:+84981604050"> +84981604050</a> </span>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggleDanger}>Đóng</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        uiReducer: state.uiReducer,
        userReducer: state.userReducer
    };
};
export default connect(mapStateToProps)(Login);
