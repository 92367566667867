import React, { Component } from 'react';
import axios, { post } from 'axios';
import { Alert, Table, FormGroup, CardHeader, Card, Pagination, PaginationItem, PaginationLink, Col, Button, Form, Input, Label, Row } from 'reactstrap';
import { api } from '../../../views'
import moment from 'moment'
import ReactFileReader from 'react-file-reader';
import qs from 'query-string'
import '../../../index.css'
import 'moment/locale/vi'
moment.locale('vi')

class StatisticMonthly extends Component {

  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: [],
      time: '',
      userId: '',
      file: null
    };
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChangefile = this.onChangefile.bind(this)
    this.fileUpload = this.fileUpload.bind(this)

  }
  async handleClick(event) {
    let search = '?'
    let time = qs.parse(this.props.location.search).time;
    let userId = qs.parse(this.props.location.search).userId;
    if (time) {
      search += '&time=' + time
    }
    if (userId) {
      search += '&userId=' + userId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actGetVKShareMonthlyStatistics(time, userId)
  }

  componentDidMount() {
    this.handleClick()
  }
  async actGetVKShareMonthlyStatistics(time, userId) {
    time = time ? moment(time) : moment().add(-1, 'month');
    let month = time.get('month') + 1;
    console.log('iii', month)
    let year = time.get('year');
    let where = { month, year, userId: userId || undefined }
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actGetVKShareMonthlyStatistic(skip, limit, '', where, '')
    if (result) {
      console.log('888', result)
      let count = result.data.count;


      let maxPage = Math.ceil(count / limit);
      //console.log({ maxPage });
      //console.log({ currentPage });

      let pagesNumber = [];

      if (maxPage <= 5) {
        for (let i = 0; i < maxPage; i++) {
          pagesNumber.push(i + 1);
        }

      }
      else if (maxPage > 5 && maxPage - 2 <= currentPage) {
        pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
      } else if (currentPage < 4) {
        pagesNumber = [1, 2, 3, 4, 5];
        if (maxPage > 5) {
          pagesNumber.push(0);
        }
      } else {
        pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
      }
      let next = currentPage + 1;

      let prev = currentPage - 1;

      this.setState({ datas: result.data.data, next, prev, count, currentPage, last: maxPage || 1, pagesNumber });
    }
  }
  formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    console.log('aaa', value)
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  async handleReport() {
    //  var result = await api.dataService.actGetReport()
    //  window.open(result.data);
    axios({
      url: `${process.env.REACT_APP_HOST || api.config.HOST}` + '/api/voucher-user-management/report-list-need-payment',
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        'Authorization': (localStorage.getItem('RFTK-GK') ? localStorage.getItem('RFTK-GK') : api.api.getToken()),
        // 'Accept-Language': api.getLang(),
        'Api-Version': 5
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Report.xlsx');
      document.body.appendChild(link);
      link.click();
    })
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { time, userId } = this.state;

    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&time=' + (time || '') + '&userId=' + (userId || 0))
    this.actGetVKShareMonthlyStatistics(time, userId)

  }
  // handleFiles = async files => {
  //   let formData = new FormData();
  //   console.log(formData.append('files', files))

  //   formData.append('files', files[0].name);

  //   var result = await api.dataService.actUpload(formData)
  //   console.log('aaa', result)
  // }

  onFormSubmit(e) {
    e.preventDefault() // Stop form submit
    this.fileUpload(this.state.file).then((response) => {
      console.log(response);
    })
    this.setState({
      file: ''
    })
  }
  onChangefile(e) {
    console.log('aaa', e.target.files)
    this.setState({ file: e.target.files[0] })
  }
  async fileUpload(file) {
    let token = localStorage.getItem('RFTK-GK') ? localStorage.getItem('RFTK-GK') : api.getToken()
    const url = api.config.HOST + '/api/voucher-user-management/import-list-paid'
    const formData = new FormData();
    formData.append('files', file)
    const config = {
      headers: {
        'Authorization': token,
        'content-type': 'multipart/form-data',
        'Api-Version': 5
      }
    }
    return await post(url, formData, config)
  }
  render() {
    var { datas, file, count, currentPage, prev, next, pagesNumber, last, time, userId } = this.state
    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <Form onSubmit={this.onSubmitSearch}>
              <Row form>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleState">ID người dùng</Label>
                    <Input type="text" id="userId" name="userId" value={userId} onChange={this.onChange.bind(this)} />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleDate">Chọn ngày tháng</Label>
                    <Input type="date" name="time" id="time" value={time} onChange={this.onChange.bind(this)} placeholder="date placeholder" />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
              </FormGroup>
            </Form>
            <Alert color="warning">
              <i className="fa fa-align-justify"></i> {datas.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
            </Alert>
            <Alert color="success" className="text-center">
              Thống kê thu nhập người dùng VKShare theo tháng !
      </Alert>


            <Card>
              <CardHeader>
                <Button color="primary" className="btn-square text-right" onClick={this.handleReport.bind(this)}>
                  <i className="fa fa-download "></i>&nbsp;Xuất báo cáo
                </Button>


                {/* <label for="upload" class="file-upload__label"> <i className="fa fa-align-justify mr-2"></i>Import lương đã trả cho VKShare</label>
                <input id="upload" class="file-upload__input" type="file" name="file-upload" value={file}
                  onChange={async e => {
                    let formData = new FormData();
                    formData.append('files', file);
                    this.setState({ file: e.target.value })

                    if (file) {
                      var result = await api.dataService.actUpload(formData)
                      console.log('anh', result)
                    }

                  }} /> */}

                <div class="file-upload" className="float-right ">
                  {/* <ReactFileReader handleFiles={this.handleFiles} fileTypes={[".csv", ".xls", ".xlsx"]}>
                    <button className=' file-upload__label'><i className="fa fa-align-justify mr-2"></i>Import lương đã trả cho VKShare</button>
                  </ReactFileReader> */}
                  <form onSubmit={this.onFormSubmit}>
                    <h4 style={{ color: 'blue', display: 'table', margin: '0 auto' }}>Import lương đã trả cho VKShare</h4>
                    <input type="file" className=' file-upload__label' onChange={this.onChangefile} />
                    <button type="submit" className=' file-upload__label'>Upload</button>
                  </form>
                </div>
              </CardHeader>
              <Table hover responsive className="table-outline mb-0 d-none d-sm-table">
                <thead className="thead-light">
                  <tr>
                    <th className="text-center"><i className="icon-people"></i></th>
                    <th>Thông tin người dùng</th>
                    <th className="text-center">Thời gian</th>
                    <th>Tổng thu nhập</th>
                    <th className="text-center">Thanh toán</th>
                  </tr>
                </thead>
                <tbody>
                  {datas !== "" ? datas.map((a, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">

                          <span >{index + 1}</span>
                        </td>
                        <td>
                          <div className="small ">
                            <span className="bold">Tên</span> | {a.userId.fullName}
                          </div>
                          <div className="small ">
                            <span className="bold">SĐT</span> | {a.userId.phone}
                          </div>
                          <div className="small ">
                            <span className="bold">Địa chỉ</span> | {a.userId.address}
                          </div>
                        </td>
                        <td className="text-center">
                          <span style={{ fontSize: 12 + 'px' }} className="bold">Tháng {a.month} năm {a.year}</span>
                        </td>
                        <td>
                          <div className="clearfix">
                            <div className="float-left">
                              <strong>{a.money ? this.formatCurrency(a.money) : '0'} VNĐ</strong>
                            </div>
                          </div>
                        </td>
                        <td>
                          {a.isPaid === true ? <Alert color="success" className="text-center">
                            Đã thanh toán hoàn tất!
      </Alert> : <Alert color="warning" className="text-center bold-x">
                              Chưa thanh toán!
      </Alert>}
                        </td>

                      </tr>)
                  }) : <div className="text-center">Không có dữ liệu !</div>}


                </tbody>
              </Table> </Card>
            <div style={{ display: 'table', margin: '0 auto' }}>
              {pagesNumber.length == 1 ? '' : <Pagination >
                <PaginationItem>
                  {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                  }
                </PaginationItem>
                <PaginationItem>
                  {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                    <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                  }
                </PaginationItem>
                {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                  return (<Pagination key={index}>
                    <PaginationItem active={currentPage == (pageNumber)} >
                      {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                        {pageNumber}
                      </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                          ...
                          </PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>)

                })
                }

                <PaginationItem>
                  {
                    next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                  }
                </PaginationItem>

                <PaginationItem>
                  {
                    currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                      <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                  }
                </PaginationItem>
              </Pagination>}

            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

export default StatisticMonthly;
