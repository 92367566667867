import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Modal,
  ModalBody,
  Form,
  FormGroup,
  Row, Col,
  Input,
  InputGroup,
  InputGroupAddon, Alert,
  InputGroupText, Label,
  ModalHeader, Pagination, PaginationItem, PaginationLink, Table
} from 'reactstrap';
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
import { api } from '../../../views'
import { connect } from "react-redux";
import qs from 'query-string'
import '../../../index.css'

function UserRow(props) {
  const product = props.product
  const userLink = `#/quanlysanpham/danhsachsanpham/${product.id}`

  async function handleDeleteProduct() {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.actDeleteProductRequest(product.id)
    }
  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={userLink}>{product.id}</a></th>
      <td className="text-center"><a href={userLink}>{product.name}</a></td>
      <td className="text-center">{product.description}</td>
      <td className="text-center">{product.categoryId ? product.categoryId.name : ''}</td>
      <td className="text-center">  <Button outline color="success" size="sm" block onClick={handleDeleteProduct}>Xóa sản phẩm</Button></td>
    </tr >
  )
}

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      name: '',
      nameError: '',
      description: '',
      descriptionError: '',
      categoryId: '',
      categoryIdError: '',
      categories: '',
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);

  }
  validate = () => {
    let nameError = "";
    let descriptionError = "";
    let categoryIdError = "";
    var { name, description, categoryId } = this.state;
    if (!name) {
      nameError = 'Trường này không được để trống !'
    }
    if (!description) {
      descriptionError = 'Trường này không được để trống !'
    }
    if (!categoryId) {
      categoryIdError = 'Vui lòng chọn danh mục sản phẩm !'
    }
    if (nameError || descriptionError || categoryIdError) {
      this.setState({ nameError, descriptionError, categoryIdError });
      return false;
    }
    return true
  }
  async handleClick(event) {
    let search = '?'
    let where = {};

    let name = qs.parse(this.props.location.search).name;
    let categoryId = qs.parse(this.props.location.search).categoryId;
    if (name) {
      where.name = { 'contains': name }
      search += '&name=' + name
    }
    if (categoryId) {
      where.categoryId = categoryId
      search += '&categoryId=' + categoryId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    console.log('111', name)
    //where
    this.actFetchProducts(where)
  }


  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }


  componentDidMount() {
    this.actFetchProducts()
    this.actGetCategory()
  }
  async actFetchProducts(where) {
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchProductsRequest(skip, limit, '', where, [{ id: 'ASC' }], 'categoryId')
    let count = result.count;
    let maxPage = Math.ceil(count / limit);
    let pagesNumber = [];
    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }
    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;
    let prev = currentPage - 1;

    this.setState({ next, prev, count, currentPage, last: maxPage || 1, pagesNumber });
  }

  async actGetCategory() {
    var result = await api.dataService.actFetchCategoriesRequest('', '', '', '')
    this.setState({ categories: result.data })
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name, description, categoryId, currentPage } = this.state;
    if (isValid) {
      var product = {
        name: name,
        description: description,
        categoryId: categoryId
      }
      await api.dataService.actAddProductRequest(product)
      this.actFetchProducts()
      this.setState({
        success: !this.state.success,
        name: '',
        description: '',
        categoryId: ''
      })
    }

  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { name, categoryId } = this.state;
    let where = {};
    // if(name === ''){
    //   return api.api.showMessage('Chưa điền tên sản phẩm ! Vui lòng kiểm tra lại','Thông báo')
    // }
    if (name) {
      where.name = { 'contains': name }
    }
    if (categoryId && categoryId.value) {
      where.categoryId = categoryId.value
    }

    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&name=' + name + '&categoryId=' + (where.categoryId || ''))
    this.actFetchProducts(where)

  }
  render() {
    var { count } = this.state
    var { listProducts } = this.props;
    listProducts = this.props.listProducts.products
    var { name, description, categoryId, categories, currentPage, prev, next, pagesNumber, last, nameError, descriptionError, categoryIdError } = this.state;
    const options = categories.length > 0 ? categories.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    }) : ''
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    {/* <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã</Label>
                        <Input type="text" id="id" name="id" value={id} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">Tên sản phẩm</Label>
                        <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    {/* <Col md={4}>
                      <FormGroup row>
                        <Label for="exampleZip">Danh mục sản phẩm</Label>
                        <Input type="select" name="categoryId" value={categoryId} id="categoryId" onChange={this.onChange.bind(this)} >
                          <option value="">-- select --</option>
                          {categories.length > 0 ? categories.map(a => {
                            return (< option key={a.id} value={a.id} > {a.name}</option>)
                          }) : ''}

                        </Input>
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Danh mục sản phẩm</Label>
                        <Select options={options}
                          onChange={(categoryId) => {
                            categoryId = categoryId || { value: '', label: '' }
                            this.setState({ categoryId })
                          }}
                          value={categoryId}
                          placeholder="Search"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <br />
          <br />
          <br />

          <Col xs={12} >
            <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm sản phẩm</Button>
          </Col>
          <br />
          <br />
          <br />
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {listProducts.length > 0 ? `Danh sách sản phẩm (${count} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {listProducts.length > 0 ?
                  <Table responsive bordered >
                    <thead>
                      <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">Tên sản phẩm</th>
                        <th className="text-center">Chi tiết sản phẩm</th>
                        <th className="text-center">Danh mục sản phẩm</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listProducts.map((product, index) =>
                        <UserRow key={index} product={product} />
                      )}
                    </tbody>
                  </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}
                <div style={{ display: 'table', margin: '0 auto' }}>
                  {pagesNumber.length == 1 ? '' : <Pagination >
                    <PaginationItem>
                      {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                      }
                    </PaginationItem>
                    <PaginationItem>
                      {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                      }
                    </PaginationItem>
                    {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                      return (<Pagination key={index}>
                        <PaginationItem active={currentPage == (pageNumber)} >
                          {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            {pageNumber}
                          </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                              ...
                          </PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>)

                    })
                    }

                    <PaginationItem>
                      {
                        next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                      }
                    </PaginationItem>

                    <PaginationItem>
                      {
                        currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>}

                </div>
              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm sản phẩm</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tên sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Chi tiết sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
                </FormGroup>
                <FormGroup >
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Danh mục sản phẩm</InputGroupText>
                    </InputGroupAddon>
                    <Input type="select" name="categoryId" value={categoryId} id="categoryId" onChange={this.onChange.bind(this)} >
                      <option value="">-- select --</option>
                      {categories.length > 0 ? categories.map(a => {
                        return (< option key={a.id} value={a.id} > {a.name}</option>)
                      }) : ''}

                    </Input>
                    <InputGroupAddon addonType="append">
                      <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {categoryIdError ? <p style={{ color: 'red', display: 'table', margin: '0 auto' }}> <i>{categoryIdError}</i> </p> : ''}
                </FormGroup>
                <FormGroup className="form-actions">
                  <Button type="submit" size="sm" color="primary">Thêm sản phẩm</Button>
                </FormGroup>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listProducts: state.listProducts,
  }
}
export default connect(mapStateToProps)(Products);