import React, { Component } from 'react';
import {
  Badge, Card, CardBody, CardHeader, Col, Row, Table, Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Pagination, PaginationItem, PaginationLink,
  InputGroupText,
  ModalHeader
} from 'reactstrap';
import { AppSwitch } from '@coreui/react'
import { api } from '../../../views'

// import usersData from './UsersData'
import moment from 'moment'
import { connect } from "react-redux";
import qs from 'query-string'
import '../../../index.css'
function UserRow(props) {
  const user = props.user
  const actGetUser = props.actGetUser
  const userLink = `#/quanlynguoidung/nguoidungvoucher/${user.id}`
  const withDrawLink = `#/quanlynguoidung/withdraw/${user.id}`
  const linkCongTien = `#/quanlynguoidung/lichsucongtien/${user.id}`
  const linkRutTien = `#/quanlynguoidung/lichsuruttien/${user.id}`
  function formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  async function ChnageUserLocker() {

    var userId = {
      userId: user.id
    }

    if (user.locked === true) {
      var result = await api.dataService.actUnBlockUser(userId)
      if (!result) {
        api.api.showMessage('Lỗi hệ thống ! Xin vui lòng thử lại sau !', 'Thông báo')
      }
      else {
        api.api.showMessage('Mở khóa người dung thành công', 'Thông báo')
      }
      await actGetUser()
    }
    else {
      var result = await api.dataService.actBlockUser(userId)
      if (!result) {
        api.api.showMessage('Lỗi hệ thống ! Xin vui lòng thử lại sau !', 'Thông báo')
      }
      else {
        api.api.showMessage('Khóa người dung thành công', 'Thông báo')
      }
      await actGetUser()
    }
  }

  return (
    <tr >
      <th scope="row" className="text-center"><a href={userLink}>{user.id}</a></th>
      <td className="text-center"><a href={userLink}>{user.account}</a></td>
      <td className="text-center"> <div>{user.fullName}</div>
        <div className="small text-muted">
          <span className="bold-x">New</span> | {moment(user.createdAt).format('LLL')}
        </div></td>
      <td className="text-center">{user.phone}</td>
      <td className="text-center">{formatCurrency(user.currentBalance)} <span style={{ float: 'right' }}> <b>VNĐ</b> </span>   <Button href={withDrawLink} color="ghost-info" size="sm" block disabled>Rút tiền</Button>
        <Button href={linkCongTien} color="ghost-info" size="sm" block>Lịch sử cộng tiền</Button>
        <Button href={linkRutTien} color="ghost-info" size="sm" block>Lịch sử rút tiền</Button>
      </td>
      {/* <td><Badge href={userLink} color={getBadge(user.locked)}>{user.locked}</Badge></td> */}
      <td className="text-center">  <AppSwitch className={'mx-1'} id="locked" name="locked" checked={user.locked ? true : false} onChange={ChnageUserLocker} color={'primary'} label /></td>
      <td className="text-center"> <img className="img-avatar-user" src={user.avatar} alt={user.fullName} style={{ height: 100, width: 100 }} /> </td>

    </tr >
  )
}

class UsersVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      account: '',
      phone: '',
      users: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: []
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  async handleClick(event) {
    let search = '?'
    let account = qs.parse(this.props.location.search).account;
    let where = {};
    if (account) {
      where.account = { 'contains': account }
      search += '&account=' + account
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actGetUser(where)
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }

  componentDidMount() {
    this.actGetUser()
  }
  async actGetUser(where) {
    let where2 = Object.assign({}, where);
    if (!where2) {
      where2 = {};
    }

    where2.roleIds = { "contains": 2 };
    where2.isVerify = true
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 10;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actFetchUsersRequest(skip, limit, '', where2, [{ id: 'ASC' }])
    let count = result.count;


    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ next, prev, count, currentPage, prev: currentPage - 1, next: currentPage + 1, last: maxPage || 1, pagesNumber });
  }

  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { account, phone } = this.state;
    let where = {};

    if (account) {
      where.account = { 'contains': account }
    }
    {/* if (phone) {
      where.phone = { 'contains': phone }
    } */}
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&account=' + account)
    this.actGetUser(where)
    // var result = await api.dataService.actFetchUsersRequest(0, 100, '', where, [{ id: 'ASC' }])
    //  this.setState({ users: result.data })

  }
  render() {

    var { account, count, prev, next, pagesNumber, last, currentPage } = this.state;
    var { listUsers } = this.props;
    listUsers = this.props.listUsers.users


    // const userList = usersData.filter((user) => user.data.id < 10)

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    {/* <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Mã</Label>
                        <Input type="text" id="id" name="id" value={id} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleState">Tên tài khoản</Label>
                        <Input type="text" id="account" name="account" value={account} onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col>
                    {/* <Col md={4}>
                      <FormGroup row>
                        <Label for="exampleZip">Số điện thoại </Label>
                        <Input type="number" id="phone" name="phone" value={phone} min='0' step='1' onChange={this.onChange.bind(this)} />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {listUsers.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {listUsers.length > 0 ?
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">ID</th>
                        <th scope="col" className="text-center">Tài khoản</th>
                        <th scope="col" className="text-center">Tên đầy đủ</th>
                        <th scope="col" className="text-center">Số điện thoại</th>
                        <th scope="col" className="text-center">Tài khoản</th>

                        <th scope="col" className="text-center">Khóa tài khoản</th>
                        <th scope="col" className="text-center">Avatar</th>
                      </tr>
                    </thead>

                    {listUsers.map((user, index) =>
                      <tbody key={index}>
                        <UserRow user={user} actGetUser={this.actGetUser.bind(this)} />
                      </tbody>)} :

                </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}
                <div style={{ display: 'table', margin: '0 auto' }}>
                  {pagesNumber.length == 1 ? '' : <Pagination >
                    <PaginationItem>
                      {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                      }
                    </PaginationItem>
                    <PaginationItem>
                      {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                      }
                    </PaginationItem>
                    {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                      return (<Pagination key={index}>
                        <PaginationItem active={currentPage == (pageNumber)} >
                          {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            {pageNumber}
                          </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                              ...
                          </PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>)

                    })
                    }

                    <PaginationItem>
                      {
                        next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                      }
                    </PaginationItem>

                    <PaginationItem>
                      {
                        currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listUsers: state.listUsers,
  }
}
export default connect(mapStateToProps)(UsersVoucher);

