import React, { Component } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Row, Col,
  Input,
  Label,
  Alert
} from 'reactstrap';
import { api } from '../../../views'
import '../../../index.css'
import moment from 'moment'


class PersonalInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      avatar: '',
      fullName: '',
      email: '',
      dob: '',
      companyName: '',
      companyAddress: '',
      bankAccountName: '',
      bankAccountNumber: '',
      bankName: '',
      bankBrand: '',
      account: '',
      id: '',
      status: false,

    };
  }

  componentDidMount() {
    this.actFetchRatingReviews()
  }
  async actFetchRatingReviews() {
    // var roleId = (sessionStorage.getItem('roleId') || []).split(',').map(v => Number(v));
    // let maxRole = Math.max(...roleId);
    let id = localStorage.getItem('userId');
    let where = {}
    where.id = id;
    var result = await api.dataService.actFetchUsersRequest('', '', '', where, '')
    console.log('555', result)
    if (result.data.length === 1)
      this.setState({
        user: result.data,
        fullName: result.data[0].fullName,
        account: result.data[0].account,
        avatar: result.data[0].avatar,
        email: result.data[0].email,
        dob: result.data[0].dob,
        companyName: result.data[0].companyName,
        companyAddress: result.data[0].companyAddress,
        bankAccountName: result.data[0].bankAccountName,
        bankAccountNumber: result.data[0].bankAccountNumber,
        bankName: result.data[0].bankName,
        bankBrand: result.data[0].bankBrand,
      })
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmit();
    }
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    var roleId = sessionStorage.getItem('roleId').split(',').map(v => Number(v));
    let maxRole = Math.max(...roleId);
    let id = maxRole - 1;
    var { account, bankAccountName, bankAccountNumber, bankBrand, avatar, bankName, companyAddress, dob, fullName, companyName, email } = this.state;
    var user = {
      id: id,
      bankAccountName: bankAccountName,
      bankAccountNumber: bankAccountNumber,
      bankBrand: bankBrand,
      bankName: bankName,
      companyAddress: companyAddress, dob: dob,
      fullName: fullName, companyName: companyName,
      email: email

    }
    var result = await api.dataService.actUpdateUSerInfo(user);
    console.log({ result })
    if (result.status == 200) {
      this.setState({ status: true })
    }
    else {
      api.api.showMessage('Lỗi hệ thống ! Vui lòng thử lại sau', 'Thông báo')
    }
  }

  render() {
    var { user, status, avatar, email, companyName, fullName, dob, companyAddress, bankName, account, bankAccountNumber, bankBrand, bankAccountName } = this.state;
    return (
      <div className="animated fadeIn" id="profile-page">
        <Row>
          <Col xs="12" sm="7">
            <h1>Hồ sơ cá nhân</h1>
            <p>Hoàn thành chi tiết hồ sơ giúp bạn tự tin hơn :)</p>
          </Col>
          <Col xs="12" sm="5">
            <Alert color="warning">
              <i className="cui-bolt icons "></i> Thông tin hồ sơ của bạn sẽ được giữ bảo mật
                    !
      </Alert>
          </Col>
          {/* <div className="float-right media-photo profile-pic-background">
            {!avatar ? <img width="220" height="220" title="Persevere" src={'assets/img/avatars/ggg.jpg'} alt="Persevere" /> : <img width="220" height="220" title="Persevere" src={avatar} alt={account} />}
          </div> */}
        </Row>
        {!status ? '' : <Alert color="warning" className="center">
          Cập nhật thông tin cá nhân thành công !
                 !
      </Alert>}

        <Form onSubmit={this.onSubmit}>
          <FormGroup row>
            <Label for="fullName" sm={2} className="label-bold"><p>Tên<span class="required">*</span> :</p></Label>
            <Col sm={10}>
              <Input type="text" name="fullName" value={fullName} onChange={this.onChange.bind(this)} id="fullName" placeholder="Tên đầy đủ của bạn ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="email" sm={2} className="label-bold"><p>Email<span class="required">*</span> :</p></Label>
            <Col sm={10}>
              <Input type="email" name="email" onChange={this.onChange.bind(this)} value={email} id="email" placeholder="Email của bạn ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="dob" sm={2} className="label-bold"><p>Ngày sinh :</p></Label>
            <Col sm={10}>
              <Input type="date" name="dob" onChange={this.onChange.bind(this)} value={dob} id="dob" placeholder="date placeholder" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="companyName" sm={2} className="label-bold"><p>Tên công ty :</p></Label>
            <Col sm={10}>
              <Input type="text" name="companyName" onChange={this.onChange.bind(this)} value={companyName} id="companyName" placeholder="Tên công ty của bạn ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="companyAddress" sm={2} className="label-bold"><p>Địa chỉ công ty :</p></Label>
            <Col sm={10}>
              <Input type="text" name="companyAddress" onChange={this.onChange.bind(this)} value={companyAddress} id="companyAddress" placeholder="Địa chỉ công ty của bạn ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bankAccountName" sm={2} className="label-bold"><p>Tên tài khoản ngân hàng<span class="required">*</span> :</p></Label>
            <Col sm={10}>
              <Input type="text" name="bankAccountName" onChange={this.onChange.bind(this)} value={bankAccountName} id="bankAccountName" placeholder="VU TUAN ANH ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bankAccountNumber" sm={2} className="label-bold"><p>Số tài khoản<span class="required">*</span> :</p></Label>
            <Col sm={10}>
              <Input type="number" name="bankAccountNumber" onChange={this.onChange.bind(this)} value={bankAccountNumber} id="bankAccountNumber" placeholder="012345678912 ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bankName" sm={2} className="label-bold"><p>Tên ngân hàng<span class="required">*</span> :</p></Label>
            <Col sm={10}>
              <Input type="text" name="bankName" value={bankName} onChange={this.onChange.bind(this)} id="bankName" placeholder="abcxyz ..." />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="bankBrand" sm={2} className="label-bold"><p>Chi nhánh ngân hàng<span class="required">*</span>:</p></Label>
            <Col sm={10}>
              <Input type="text" name="bankBrand" value={bankBrand} onChange={this.onChange.bind(this)} id="bankBrand" placeholder="cầu giấy ..." />
            </Col>
          </FormGroup>
          <Button color="success" className="float-right" style={{ margin: ' 10px 0' }}>Lưu</Button>
        </Form>
      </div>
    );
  }
}


export default PersonalInformation;