var initialState = {
  configs: []
}

const listConfigs = (state = initialState, action) => {
  let configs = [...state.configs];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_CONFIGS':
      // console.log({ action })
      return Object.assign({}, state, { configs: action.config });
    // case 'ADD_CONFIGS':
    //   console.log({ action })
    //   configs.push(action.config);
    //   return { ...state, configs };
    case 'UPDATE_CONFIGS':
      // console.log({ actionUpdate: action })
      id = action.config.id;
      index = findIndex(state, { id });
      if (index == -1) {
        configs.push(action.config);
      } else {
        configs[index] = action.config
      }
      // console.log('configs', configs)
      return { ...state, configs };
    // case 'DELETE_PRODUCT':
    //   console.log({ action })
    //   index = findIndex(state, action.id);

    //   configs.splice(index, 1);
    //   return { ...state, products };
    default: return state;
  }
}

var findIndex = (configs, id) => {
  let result = -1;
  configs.configs.forEach((config, index) => {
    if (config.id === id) {
      result = index;
    }
  });

  return result;
}

export default listConfigs;
