import React, { Component } from 'react';
import { Button, Form, FormGroup, InputGroupAddon, Col, InputGroup, Input, Card, CardBody, CardFooter, InputGroupText, Row, Container } from 'reactstrap';
import { api } from '../../../views'
class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',

    };


  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    var { username, passwordold, passwordnews, passwordnew, id } = this.state;
    var password = {
      account: username,

    }
    // api.dataService.actUpdateRoomRequest(rooms)
    // if (id) {
    //     api.dataService.actUpdateRoomRequest(rooms)
    // }
    // else {
    //     api.dataService.actAddRoomRequest(rooms)
    // }
    var result = await api.dataService.actResetPassword(password)
    console.log('iii', result)
    // if (result.status === 200) {
    //   api.api.showMessage('Khôi phục mật khẩu thành công !', 'Thông báo')
    // }
    // this.props.history.push('/login')
    // api.dataService.actFetchRoomsRequest();
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  render() {
    return (
      <Container>
        <Row className="justify-content-center animated fadeIn">
          <Col md="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>Tài khoản</InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" id="username" name="username" onChange={this.onChange.bind(this)} />
                      <InputGroupAddon addonType="append">
                        <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="form-actions text-center">
                    <Button type="submit" size="sm" color="primary">Reset mật khẩu</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPassword;
