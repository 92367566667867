import React, { Component } from 'react';
import {
  Card, CardBody, CardHeader, Col, Row, Table, Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import { api } from '../../../views'

// import usersData from './UsersData'
import moment from 'moment'
import { connect } from "react-redux";
import qs from 'query-string'
import '../../../index.css'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
function UserRow(props) {
  const data = props.data
  const actGetStoreSpecificProduct = props.actGetStoreSpecificProduct
  const url = `#/quanlysanpham/danhsachsanpham/${data.productId.id}`
  const url2 = `#/quanlycuahang/stores/${data.storeId.id}`
  // const linkCongTien = `#/quanlynguoidung/lichsucongtien/${user.id}`
  // const linkRutTien = `#/quanlynguoidung/lichsuruttien/${user.id}`
  function formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }

  async function ChnageUserLocker() {
  }

  return (
    <tr >
      <th scope="row" className="text-center">{data.id}</th>
      <td className="text-left">
        <div className="small ">
          <span className="bold">Tên</span> | {data.productId.name}
        </div>
        <div className="small ">
          <span className="bold">Mô tả</span> | {data.productId.description}
        </div>
        <div className="small ">
          <span className="bold"><a href={url}>Xem chi tiết</a></span>
        </div>
      </td>
      <td className="text-left">
        <div className="small ">
          <span className="bold">Tên</span> | {data.storeId.name}
        </div>
        <div className="small ">
          <span className="bold">Mô tả</span> | {data.storeId.description}
        </div>
        <div className="small ">
          <span className="bold"><a href={url2}>Xem chi tiết</a></span>
        </div>
      </td>
      <td className="text-center bold">{moment(data.createdAt).format('lll')}</td>
      <td className="text-center">
        {!data.activeBy ? <div className="text-center bold-x">Không có dữ liệu</div> : <div className="small ">
          <span className="bold">Tên</span> | {data.activeBy.fullName}
        </div>
        }

        {!data.activeBy ? <div className="text-center bold-x">Không có dữ liệu</div> : <div className="small ">
          <span className="bold">Tài khoản</span> | {data.activeBy.account}
        </div>
        }
      </td>

      <td className="text-center">{formatCurrency(data.price)} <span style={{ float: 'right' }}> <b>VNĐ</b> </span>
      </td>
      {/* <td><Badge href={userLink} color={getBadge(user.locked)}>{user.locked}</Badge></td> */}
      {/* <td className="text-center">  <AppSwitch className={'mx-1'} id="locked" name="locked" checked={user.locked ? true : false} onChange={ChnageUserLocker} color={'primary'} label /></td> */}
      {/* <td className="text-center"> <img className="img-avatar-user" src={user.avatar} alt={user.fullName} style={{ height: 100, width: 100 }} /> </td> */}

    </tr >
  )
}

class SpecificsApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      account: '',
      phone: '',
      datas: [],
      count: 0,
      prev: 0,
      next: 2,
      last: 0,
      first: 1,
      currentPage: 1,
      pagesNumber: [],
      selectValue: '',
      listProducts: [],
      listStores: [],
      storeId: ''
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  async handleClick(event) {
    let search = '?'
    let productId = qs.parse(this.props.location.search).productId;
    let storeId = qs.parse(this.props.location.search).storeId;
    let where = {};
    if (productId) {
      where.productId = productId
      search += '&productId=' + productId
    }
    if (storeId) {
      where.storeId = storeId
      search += '&storeId=' + storeId
    }
    if (event) {
      search += '&page=' + event;
    }
    await this.props.history.push(search)
    this.actGetStoreSpecificProduct(where)
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }

  componentDidMount() {
    this.actGetStoreSpecificProduct()
    this.actGetProduct()
    this.actGetStore()
  }
  async actGetStore() {
    var result = await api.dataService.actFetchStoresRequest('', '', '', '', '')
    console.log('ggg', result)
    this.setState({ listStores: result.data.data })
  }
  async actGetProduct() {
    var result = await api.dataService.actFetchProductsRequest('', '', '', '', '')
    this.setState({ listProducts: result.data })
  }
  async actGetStoreSpecificProduct(where) {
    let where2 = Object.assign({}, where);
    if (!where2) {
      where2 = {};
    }
    where2.isActive = true
    where2.isDelete=false
    let urlQueryObj = qs.parse(this.props.location.search);
    let currentPage = Number(urlQueryObj.page || 1);
    if (isNaN(currentPage) || currentPage < 1) {
      currentPage = 1;
    }
    let limit = urlQueryObj.limit || 2;
    let skip = (currentPage - 1) * limit;
    var result = await api.dataService.actGetStoreSpecificProducts(skip, limit, '', where2, '')
    let count = result.data.count;
    let maxPage = Math.ceil(count / limit);
    //console.log({ maxPage });
    //console.log({ currentPage });

    let pagesNumber = [];

    if (maxPage <= 5) {
      for (let i = 0; i < maxPage; i++) {
        pagesNumber.push(i + 1);
      }

    }
    else if (maxPage > 5 && maxPage - 2 <= currentPage) {
      pagesNumber = [0, maxPage - 4, maxPage - 3, maxPage - 2, maxPage - 1, maxPage];
    } else if (currentPage < 4) {
      pagesNumber = [1, 2, 3, 4, 5];
      if (maxPage > 5) {
        pagesNumber.push(0);
      }
    } else {
      pagesNumber = [0, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, 0];
    }
    let next = currentPage + 1;

    let prev = currentPage - 1;

    this.setState({ datas: result.data.data, next, prev, count, currentPage, last: maxPage || 1, pagesNumber });
  }

  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var { selectValue, storeId } = this.state;
    let where = {};

    if (selectValue && selectValue.value) {
      where.productId = selectValue.value
    }

    if (storeId && storeId.value) {
      where.storeId = storeId.value
    }


    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&productId=' + (selectValue.value || '') + '&storeId=' + (storeId.value || ''))
    this.actGetStoreSpecificProduct(where)
  }
  render() {

    var { datas, listStores, storeId, count, selectValue, prev, next, pagesNumber, last, currentPage, listProducts } = this.state;
    console.log('data', datas)
    const options = listProducts.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    })
    const options2 = listStores.map((a) => {
      return (
        { label: a.name, value: a.id, key: a.id }

      )
    })


    // const userList = usersData.filter((user) => user.data.id < 10)
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Sản phẩm</Label>
                        <Select options={options}
                          onChange={(selectValue) => {
                            selectValue = selectValue || { value: '', label: '' }
                            this.setState({ selectValue })
                          }}
                          value={selectValue}
                          placeholder="Search"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Cửa hàng</Label>
                        <Select options={options2}
                          onChange={(storeId) => {
                            storeId = storeId || { value: '', label: '' }
                            this.setState({ storeId })
                          }}
                          value={storeId}
                          placeholder="Search"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {datas.length > 0 ? `Dữ liệu (${count} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {datas.length > 0 ?
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">#</th>
                        <th scope="col" className="text-left">Thông tin sản phẩm</th>
                        <th scope="col" className="text-left">Thông tin cửa hàng</th>
                        <th scope="col" className="text-center">Thời gian tạo</th>
                        <th scope="col" className="text-center">Người duyệt</th>
                        <th scope="col" className="text-center">Thành tiền</th>
                      </tr>
                    </thead>

                    {datas.map((data, index) =>
                      <tbody key={index}>
                        <UserRow data={data} actGetStoreSpecificProduct={this.actGetStoreSpecificProduct.bind(this)} />
                      </tbody>)}

                  </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}
                <div style={{ display: 'table', margin: '0 auto' }}>
                  {pagesNumber.length == 1 ? '' : <Pagination >
                    <PaginationItem>
                      {prev === 0 ? <PaginationLink className="hide">First</PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(1)} id="first">First</PaginationLink>
                      }
                    </PaginationItem>
                    <PaginationItem>
                      {prev < 1 ? <PaginationLink className="hide"><i className="cui-chevron-left icons "></i></PaginationLink> :
                        <PaginationLink onClick={() => this.handleClick(prev)} id="prev"><i className="cui-chevron-left icons "></i></PaginationLink>
                      }
                    </PaginationItem>
                    {!pagesNumber ? '' : pagesNumber.map((pageNumber, index) => {
                      return (<Pagination key={index}>
                        <PaginationItem active={currentPage == (pageNumber)} >
                          {pageNumber ? <PaginationLink onClick={() => { this.handleClick(pageNumber) }} key={index} >
                            {pageNumber}
                          </PaginationLink> : <PaginationLink className="disabled" onClick={() => { this.handleClick(pageNumber) }} key={index} >
                              ...
                          </PaginationLink>
                          }
                        </PaginationItem>
                      </Pagination>)

                    })
                    }

                    <PaginationItem>
                      {
                        next > last ? <PaginationLink className="hide"><i className="cui-chevron-right icons "></i></PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(next)} id="next" > <i className="cui-chevron-right icons "></i></PaginationLink>
                      }
                    </PaginationItem>

                    <PaginationItem>
                      {
                        currentPage === last ? <PaginationLink className="hide">Last</PaginationLink> :
                          <PaginationLink onClick={() => this.handleClick(last)} id="last" >Last</PaginationLink>
                      }
                    </PaginationItem>
                  </Pagination>}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listUsers: state.listUsers,
  }
}
export default connect(mapStateToProps)(SpecificsApproved);

