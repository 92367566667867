import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardHeader, Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col, Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalHeader, Alert, Table
} from 'reactstrap';
import { api } from '../../../views'
class AreaService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      areaService: '',
      areaCategory: '',
      areaCategoryList: '',
      id: '',
      name: '',
      nameError: '',
      nameAreaCategory: ''
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }
  componentDidMount() {
    this.getAreaCategoryList()
    this.getAreaService()
    if (this.props.match.params.id) {
      this.getCategoryAppId()
    }
    // } else {
    //   api.api.showMessage('Lỗi đường Link, vui lòng thử lại', 'Thông báo');
    //   window.location.href = "/#/danhmucsanphamtheoapp/danhmucsanpham"
    // }
  }
  async getAreaService() {
    var id = this.props.match.params.id
    var result = await api.dataService.getCategoryApp(id, "2")
    this.setState({
      areaService: result.data.data
    })
  }
  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  async getAreaCategoryList() {
    var result = await api.dataService.getCategoryApp("", "1")
    this.setState({
      areaCategoryList: result.data.data
    })
  }
  async getCategoryAppId() {
    var result = await api.dataService.getCategoryAppId(this.props.match.params.id)
    this.setState({
      id: result.data.data[0].id,
      nameAreaCategory: result.data.data[0].name,
    })
  }
  updateAreaCategory = async (e) => {
    e.preventDefault();
    e.target.reset();
    var { nameAreaCategory } = this.state;
    var category = {
      name: nameAreaCategory
    }
    if (!nameAreaCategory) {
      api.api.showMessage('Vui lòng không bỏ trống tên danh mục miền', 'Thông báo');
    } else {
      await api.dataService.updateCategoryApp(this.props.match.params.id, category)
      this.setState({
        nameAreaCategory: '',
      })
      setTimeout(() => {
        window.location.reload()
      }, 500);
    }

  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  validate = () => {
    let nameError = "";
    var { name } = this.state;
    if (!name) {
      nameError = 'Tên khu vực không được để trống !'
    }
    if (nameError) {
      this.setState({ nameError });
      return false;
    }
    return true
  }
  onSubmit = async e => {
    e.preventDefault();
    e.target.reset()
    const isValid = this.validate()
    var { name } = this.state;
    if (isValid) {
      var category = {
        name: name
      }
      await api.dataService.addNewCategoryApp(this.props.match.params.id, "2", category)

      this.setState({
        success: !this.state.success,
        name: '',
      })
      setTimeout(() => {
        this.getAreaService()
      }, 500);
    }
  }
  handeleDeleteCategory(data) {
    if (confirm('Bạn chắc chắn muốn xóa ?')) { //eslint-disable-line
      api.dataService.deleteCategoryApp(data)
      setTimeout(() => {
        this.getCategoryApp(0)
      }, 500);
    }
  }
  render() {
    var { areaService, id, name, nameError, nameAreaCategory, areaCategoryList } = this.state
    return (
      <div className="animated fadeIn">
        <Row>
          {this.props.match.params.id ?
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify"></i> Cập nhật danh mục
              </CardHeader>
                <CardBody>
                  <Row>

                    {/* <Col md={4}>
                    <Label for="exampleState">Danh mục sản phẩm</Label>
                    <Select options={options}
                      onChange={(productCategoryId) => {
                        productCategoryId = productCategoryId || { value: '', label: '' }
                        this.setState({ productCategoryId })
                      }}
                      value={productCategoryId}
                      placeholder="Search"
                    />
                  </Col> */}
                    <Col md={4}>
                      <Form onSubmit={this.updateAreaCategory}>
                        {/* <FormGroup>
                        <Label>Mã danh mục miền</Label>
                        <Input type="text" id="id" name="id" value={id} readOnly></Input><br></br>
                      </FormGroup> */}
                        <FormGroup>
                          <Label>Tên danh mục miền</Label>
                          <Input type="text" id="nameAreaCategory" name="nameAreaCategory" value={nameAreaCategory} onChange={this.onChange}></Input>
                        </FormGroup>
                        <Button type="submit" color="success" className="float-right" style={{ margin: ' 10px 0' }}>Lưu</Button>
                      </Form>
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
            : null}
          {this.props.match.params.id ?
            <Col xs={12} >
              <Button active color="success" onClick={this.toggleSuccess} className="float-right">Thêm danh mục dịch vụ theo miền vào: {nameAreaCategory}</Button>
            </Col>
            : null}
          <br />
          <br />
          <br />
          <Col xs={12}>
            <Card>
              <CardHeader>
                {/* <i className="fa fa-align-justify"></i> {listCategories.length > 0 ? `Danh mục sản phẩm (${listCategories.length} mục)` : 'Đang xử lý ...'} */}
              </CardHeader>
              <CardBody>
                {
                  areaService.length > 0 ?
                    <Table responsive bordered>
                      <thead>
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Tên danh  mục</th>
                          <th className="text-center">Thuộc danh mục miền</th>
                        </tr>
                      </thead>
                      <tbody>
                        {areaService.map((areaService, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">{areaService.id}</td>
                              <td className="text-center">
                                <a href={"/#/danhmucsanphamtheoapp/danhsachsanpham/" + areaService.id}>{areaService.name} </a>
                              </td>
                              <td className="text-center">
                                {/* <Button size="sm" outline color="danger" size="sm" block onClick={() => this.handeleDeleteCategory(areaService.id)}>Xóa</Button> */}
                                {areaCategoryList ? areaCategoryList.find(x => x.id === areaService.parentid).name : null}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                    : <Alert color="warning" className="center">
                      Không có dữ liệu !
                  </Alert>
                }

              </CardBody>
            </Card>
          </Col>
          <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
            className={'modal-success ' + this.props.className}>
            <ModalHeader toggle={this.toggleSuccess}>Thêm danh mục dịch vụ theo miền vào: {nameAreaCategory}</ModalHeader>
            <ModalBody>
              <Form onSubmit={this.onSubmit}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Tên danh mục dịch vụ theo miền</InputGroupText>
                    </InputGroupAddon>
                    <Input type="text" id="name" name="name" value={name} onChange={this.onChange.bind(this)} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText></InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {nameError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{nameError}</i> </p> : ''}
                </FormGroup>

                <FormGroup>
                  {/* <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Chi tiết danh mục sản phẩm</InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                        <InputGroupAddon addonType="append">
                          <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {descriptionError ? <p style={{ color: 'red', textAlign: 'center' }}> <i>{descriptionError}</i> </p> : ''}
                    </FormGroup>
                    <FormGroup className="form-actions">
                       */}
                </FormGroup>
                <Button type="submit" size="sm" color="primary">Thêm danh mục dịch vụ theo miền</Button>
              </Form>
            </ModalBody>
          </Modal>
        </Row>
      </div>
    )
  }
}
export default AreaService;