import React, { Component } from "react";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CustomInput,
} from "reactstrap";
import qs from "query-string";
import { api } from "../../../views";
import "../../../index.css";
import StarRatingComponent from "react-star-rating-component";
import { number } from "prop-types";
import moment from "moment";

class BillCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // rating: 0,
      billId: "",
      customerName: "",
      customerPhone: "",
      status: "",
      description: "",
      check: false,
      nextStage: number,
      id: number,
      // customerNote: '',
      // barcode: '',
      // ratingIds: [],
      // ratingId: []
    };
  }

  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  componentDidMount() {
    // this.actCheckBarcode()
    // this.actGetRating()
  }
  componentWillMount() {}
  // actCheckBarcode() {
  //   var billId = qs.parse(this.props.location.search)
  //   if (billId.billId) {
  //     this.setState({ billId: billId.billId })
  //   }
  //   else {
  //     alert('Link không tồn tại !')
  //     // this.props.history.push('/login')
  //   }
  // }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value,
    });
  };
  handleRatingCheck = async (event) => {
    var { ratingId } = this.state;
    const target = event.target;
    const value = target.id;

    if (ratingId) {
      var array = [...ratingId];
    } else {
      var array = [];
    }
    if (target.checked) {
      array.push(parseInt(value));
    } else {
      array = array.filter((v) => v !== parseInt(value));
    }
    await this.setState({
      ratingId: array,
    });
  };
  async actRating() {
    const { billId } = this.state;
    // var barcode = qs.parse(this.props.location.search)
    // if (barcode) {
    var result = await api.dataService.actBillCheck(billId);
    if (result.data.length != 0 && typeof result.data !== "string") {
      // alert('Cảm ơn quý khách đã đánh giá chất lượng dịch vụ !')
      // api.api.showConfirm('Thông báo', 'Cảm ơn', 'OK', 'No', 'OK', 'No')
      // this.props.history.push('/404')
      this.setState({
        customerName: result.data.customerName,
        customerPhone: result.data.customerPhone,
        description: result.data.description,
        status: result.data.name,
        nextStage: result.data.nextStageBefore,
        id: result.data.id,
        check: true,
      });
    } else {
      this.setState({
        check: false,
      });
      // alert(
      //   "Mã hóa đơn không tồn tại ! Quý khách vui lòng thử lại với mã khác, xin cảm ơn !"
      // );
    }

    // }
    // else {
    //   alert('Barcode không tồn tại !')
    // }
  }
  async actGetRating() {
    var result = await api.dataService.actFetchRating("", "", "", "", "");
    if (result.status === 200) {
      this.setState({ ratingIds: result.data.data });
    }
  }
  render() {
    const {
      billId,
      customerName,
      customerPhone,
      description,
      status,
      check,
      nextStage,
      id,
    } = this.state;
    var barcode = qs.parse(this.props.location.search);
    // console.log('uuu', ratingId)
    console.log(check);
    return (
      <div className="customerreviews">
        <Alert color="danger" className="center">
          Trạng Thái Hóa Đơn
        </Alert>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.actRating();
          }}
        >
          <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold">
              Mã hóa đơn :{" "}
            </Label>
            <Col sm={9}>
              <Input
                type="number"
                name="billId"
                value={billId}
                id="billId"
                min={0}
                step={1}
                onChange={this.onChange.bind(this)}
                placeholder="Mời bạn nhập vào mã hóa đơn ..."
                // readOnly={check}
              />
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label for="exampleEmail" sm={3}>BillId</Label>
            <Col sm={9}>
              <Input type="number" name="billId" id="billId" placeholder="with a placeholder" />
            </Col>
          </FormGroup> */}
          {/* <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold rating">Chất lượng dịch vụ : </Label>
            <Col sm={9} >
              <StarRatingComponent
                name="rate1"
                starCount={5}
                value={rating}
                onStarClick={this.onStarClick.bind(this)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for="exampleText" sm={3} className="bold">Ý kiến khác :</Label>
            <Col sm={9}>
              <Input type="textarea" name="customerNote" value={customerNote} id="customerNote" onChange={this.onChange.bind(this)} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleCheckbox" sm={3} className="bold">Nhận xét :</Label>
            <Col sm={9}>
              {!ratingIds ? '' : ratingIds.map((a, index) => {
                return (
                  <CustomInput type="checkbox" id={a.id} name={a.id} key={index} label={a.name} onChange={this.handleRatingCheck.bind(this)} />
                )
              })}
            </Col>


          </FormGroup> */}
          {check ? (
            <>
              <div
                style={{ textAlign: "center", color: "red", fontSize: "20px" }}
              >
                Thông Tin Hóa Đơn
              </div>
              <FormGroup row>
                <Label for="exampleCheckbox" sm={3} className="bold">
                  Tên Khách Hàng :
                </Label>
                <Col sm={9}>
                  <span className="billCheckSpan">{customerName}</span>
                </Col>
              </FormGroup>{" "}
              {/* <FormGroup row>
                <Label for="exampleCheckbox" sm={3} className="bold">
                  SĐT :
                </Label>
                <Col sm={9}>
                  {" "}
                  <span className="billCheckSpan">{customerPhone}</span>
                </Col>
              </FormGroup>{" "} */}
              <FormGroup row>
                <Label for="exampleCheckbox" sm={3} className="bold">
                  Trạng thái :
                </Label>
                <Col sm={9}>
                  {" "}
                  <span
                    className="billCheckSpan"
                    style={{ color: "blue", fontWeight: "700" }}
                  >
                    {status}
                  </span>
                </Col>
              </FormGroup>{" "}
              <FormGroup row>
                <Label for="exampleCheckbox" sm={3} className="bold">
                  Chi tiết trạng thái :
                </Label>
                <Col sm={9}>
                  {" "}
                  <span className="billCheckSpan">{description}</span>
                </Col>
              </FormGroup>{" "}
              {id == 5 ? (
                <FormGroup row>
                  <Label for="exampleCheckbox" sm={3} className="bold">
                    Ngày nhận hàng :
                  </Label>
                  <Col sm={9}>
                    {" "}
                    <span className="billCheckSpan">
                      {moment(nextStage).format("L")}
                    </span>
                  </Col>
                </FormGroup>
              ) : null}
            </>
          ) : 
          // (
          //   <Alert color="danger" className="center">
          //     Mã hóa đơn không tồn tại ! Quý khách vui lòng thử lại với mã khác,
          //     xin cảm ơn !
          //   </Alert>
          // )
          null}
          <FormGroup check row>
            <Col className="right">
              <Button
                className="right"
                color="success"
                onClick={this.actRating.bind(this)}
              >
                Kiểm Tra
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

export default BillCheck;
