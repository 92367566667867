var initialState = {
  products: []
}

const listProducts = (state = initialState, action) => {
  let products = [...state.products];
  var index = -1;
  var id = '';
  switch (action.type) {
    case 'FETCH_PRODUCTS':
      // console.log({ action })
      return Object.assign({}, state, { products: action.product });
    case 'ADD_PRODUCT':
      // console.log({ action })
      products.push(action.product);
      return { ...state, products };
    case 'UPDATE_PRODUCT':
      // console.log({ actionUpdate: action })
      id = action.product.id;
      index = findIndex(state, { id });
      if (index == -1) {
        products.push(action.product);
      } else {
        products[index] = action.product
      }
      // console.log('products', products)
      return { ...state, products };
    case 'DELETE_PRODUCT':
      // console.log({ action })
      index = findIndex(state, action.id);

      products.splice(index, 1);
      return { ...state, products };
    default: return state;
  }
}

var findIndex = (products, id) => {
  let result = -1;
  products.products.forEach((product, index) => {
    if (product.id === id) {
      result = index;
    }
  });

  return result;
}

export default listProducts;
