import React from 'react';
import Loadable from 'react-loadable'
import './index.css'
import DefaultLayout from './containers/DefaultLayout';

function Loading() {
    return <div> <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div><span>Hệ thống đang xử lý vui lòng đợi trong giây lát</span> </div>;
}

const StorePartners = Loadable({
    loader: () => import('./views/QuanLyCuaHang/StorePartners'),
    loading: Loading,
});
const StorePartner = Loadable({
    loader: () => import('./views/QuanLyCuaHang/StorePartners/StorePartner'),
    loading: Loading,
});

const Configs = Loadable({
    loader: () => import('./views/CauHinhHeThong/Configs'),
    loading: Loading,
});
const Config = Loadable({
    loader: () => import('./views/CauHinhHeThong/Configs/Config'),
    loading: Loading,
});
const UsersVoucher = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersVoucher'),
    loading: Loading,
});
const UserVoucher = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersVoucher/UserVoucher'),
    loading: Loading,
});
const WithDraw = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersVoucher/WithDraw'),
    loading: Loading,
});

const ListCongTien = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersVoucher/ListCongTien'),
    loading: Loading,
});
const ListRutTien = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersVoucher/ListRutTien'),
    loading: Loading,
});


const UsersPending = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersPending'),
    loading: Loading,
});
const UserPending = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/UsersPending/UserPending'),
    loading: Loading,
});


const ButtonDropdowns = Loadable({
    loader: () => import('./views/QuanLySanPham/ButtonDropdowns'),
    loading: Loading,
});


const Buttons = Loadable({
    loader: () => import('./views/QuanLySanPham/Buttons'),
    loading: Loading,
});

const PriceProducts = Loadable({
    loader: () => import('./views/QuanLySanPham/PriceProducts'),
    loading: Loading,
});

const PriceProduct = Loadable({
    loader: () => import('./views/QuanLySanPham/PriceProducts/PriceProduct'),
    loading: Loading,
});

const Products = Loadable({
    loader: () => import('./views/QuanLySanPham/Products'),
    loading: Loading,
});

const Vouchers = Loadable({
    loader: () => import('./views/QuanLyVoucher/Vouchers'),
    loading: Loading,
});

const Voucher = Loadable({
    loader: () => import('./views/QuanLyVoucher/Vouchers/Voucher'),
    loading: Loading,
});

const Product = Loadable({
    loader: () => import('./views/QuanLySanPham/Products/Product'),
    loading: Loading,
});
const Categories = Loadable({
    loader: () => import('./views/QuanLySanPham/Category'),
    loading: Loading,
});
const Category = Loadable({
    loader: () => import('./views/QuanLySanPham/Category/Category'),
    loading: Loading,
});

const Dashboard = Loadable({
    loader: () => import('./views/Dashboard'),
    loading: Loading,
});

const Stores = Loadable({
    loader: () => import('./views/QuanLyCuaHang/Stores'),
    loading: Loading,
});

const StoreUsers = Loadable({
    loader: () => import('./views/QuanLyCuaHang/Stores/StoreUsers'),
    loading: Loading,
});

const StoreAreas = Loadable({
    loader: () => import('./views/QuanLyCuaHang/StoreAreas'),
    loading: Loading,
});
const Store = Loadable({
    loader: () => import('./views/QuanLyCuaHang/Stores/Store'),
    loading: Loading,
});
const StoreArea = Loadable({
    loader: () => import('./views/QuanLyCuaHang/StoreAreas/StoreArea'),
    loading: Loading,
});

const Debts = Loadable({
    loader: () => import('./views/Report/Debts'),
    loading: Loading,
});
const CustomerUseService = Loadable({
    loader: () => import('./views/Report/CustomerUseService/CustomerUseService'),
    loading: Loading,
});
const ReportVoucherPartner = Loadable({
    loader: () => import('./views/Report/voucherPartner/report'),
    loading: Loading,
});
const Debt = Loadable({
    loader: () => import('./views/Report/Debts/Debt'),
    loading: Loading,
});

const Ratings = Loadable({
    loader: () => import('./views/Rating/Ratings'),
    loading: Loading,
});

const Modals = Loadable({
    loader: () => import('./views/Report/Modals'),
    loading: Loading,
});
const Password = Loadable({
    loader: () => import('./views/Password/ChangePassword'),
    loading: Loading,
});
const ResetPassword = Loadable({
    loader: () => import('./views/Password/ResetPassword'),
    loading: Loading,
});

const PasswordStore = Loadable({
    loader: () => import('./views/Password/ChangePasswordStore'),
    loading: Loading,
});

const Users = Loadable({
    loader: () => import('./views/Users/Users'),
    loading: Loading,
});
const Bill = Loadable({
    loader: () => import('./views/HoaDon/DanhSachHoaDon/Bills'),
    loading: Loading,
});
const BillCancel = Loadable({
    loader: () => import('./views/HoaDon/DanhSachHoaDon/BillCancel'),
    loading: Loading,
});


const User = Loadable({
    loader: () => import('./views/Users/User'),
    loading: Loading,
});
const Email = Loadable({
    loader: () => import('./views/Report/Email'),
    loading: Loading,
});

const Sms = Loadable({
    loader: () => import('./views/Report/Sms'),
    loading: Loading,
});
const FinishWork = Loadable({
    loader: () => import('./views/Report/FinishWork'),
    loading: Loading,
});
const RevenueStructure = Loadable({
    loader: () => import('./views/Report/RevenueStructure/RevenueStructure'),
    loading: Loading,
});
const TurnOver = Loadable({
    loader: () => import('./views/Report/TurnOver'),
    loading: Loading,
});
const PersonalInformation = Loadable({
    loader: () => import('./views/ThongTinNguoiDung/PersonalInformation'),
    loading: Loading,
});
const OverTime = Loadable({
    loader: () => import('./views/Report/OverTime'),
    loading: Loading,
});

const PartnerTurnOver = Loadable({
    loader: () => import('./views/Report/PartnerTurnOver'),
    loading: Loading,
});
const Rating = Loadable({
    loader: () => import('./views/Report/Rating'),
    loading: Loading,
});

const RatingStar = Loadable({
    loader: () => import('./views/Report/RatingStar'),
    loading: Loading,
});
const RatingType = Loadable({
    loader: () => import('./views/Report/RatingType'),
    loading: Loading,
});
const StoreTurnOver = Loadable({
    loader: () => import('./views/Report/StoreTurnOver'),
    loading: Loading,
});

const SpecificsPending = Loadable({
    loader: () => import('./views/LuuTruSanPham/SpecificsPending'),
    loading: Loading,
});
const SpecificsApproved = Loadable({
    loader: () => import('./views/LuuTruSanPham/SpecificsApproved'),
    loading: Loading,
});
// const ChiTietDanhSachCuaHang = Loadable({
//   loader: () => import('./views/QuanLyCuaHang/DanhSachCuaHang/ChiTietDanhSachCuaHang'),
//   loading: Loading,
// });

const StatisticMonthly = Loadable({
    loader: () => import('./views/QuanLyNguoiDung/StatisticMonthly'),
    loading: Loading,
});

const VoucherPartner = Loadable({
    loader: () => import('./views/QuanLyVoucher/VoucherPartner/VoucherPartner'),
    loading: Loading
})

const VoucherUsedByCustomer = Loadable({
    loader: () => import('./views/QuanLyVoucher/VoucherUsedByCustomer/VoucherUsedByCustomer'),
    loading: Loading
})

const Partners = Loadable({
    loader: () => import('./views/QuanLyDoiTac/Partners/Partners'),
    loading: Loading
})

const Partner = Loadable({
    loader: () => import('./views/QuanLyDoiTac/Partners/Partner'),
    loading: Loading
})

const CustomerRatings = Loadable({
    loader: () => import('./views/XepHangKhachHang/CustomerRatings'),
    loading: Loading
})

const ConfigBanner = Loadable({
    loader: () => import('./views/CauHinhBannerAPP/ConfigBanner'),
    loading: Loading
})
const AppProductList = Loadable({
    loader: () => import('./views/DanhMucSanPhamApp/AppProductList'),
    loading: Loading
})
const AppProductCategory = Loadable({
    loader: () => import('./views/DanhMucSanPhamApp/AppProductCategory'),
    loading: Loading
})
const AppProductArea = Loadable({
    loader: () => import('./views/DanhMucSanPhamApp/AreaCategory'),
    loading: Loading
})
const AppProductAreaService = Loadable({
    loader: () => import('./views/DanhMucSanPhamApp/AreaService'),
    loading: Loading
})

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: '/', exact: true, name: '', component: DefaultLayout },
    { path: '/dashboard', name: 'Trang chủ', component: Dashboard },
    { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
    { path: '/buttons/buttons', name: 'Buttons', component: Buttons },

    { path: '/config/configmanagament', exact: true, name: 'Cấu hình hệ thống', component: Configs },
    { path: '/config/configmanagament/:id', exact: true, name: 'Chi tiết', component: Config },
    { path: '/quanlynguoidung/lichsucongtien/:id', exact: true, name: 'Lịch sử cộng tiền', component: ListCongTien },
    { path: '/quanlynguoidung/lichsuruttien/:id', exact: true, name: 'Lịch sử rút tiền', component: ListRutTien },

    { path: '/quanlynguoidung/withdraw/:id', exact: true, name: 'Rút tiền', component: WithDraw },

    { path: '/voucher/danhsachvoucher', exact: true, name: 'Danh sách voucher', component: Vouchers },
    { path: '/voucher/danhsachvoucher/:id', exact: true, name: 'Chi tiết', component: Voucher },

    { path: '/voucher/partner', exact: true, name: 'Voucher đối tác', component: VoucherPartner },
    { path: '/voucher/list-customer', exact: true, name: 'Danh sách khách hàng sử dụng voucher đối tác', component: VoucherUsedByCustomer },

    { path: '/quanlydoitac/partner', exact: true, name: 'Danh sách đối tác', component: Partners },
    { path: '/quanlydoitac/partner/:id', exact: true, name: 'Danh sách đối tác', component: Partner },


    { path: '/quanlynguoidung/nguoidungvoucher', exact: true, name: 'Danh sách người dùng', component: UsersVoucher },
    { path: '/quanlynguoidung/nguoidungvoucher/:id', exact: true, name: 'Chi tiết', component: UserVoucher },
    { path: '/quanlynguoidung/nguoiduyetvoucher', exact: true, name: 'Danh sách chờ duyệt', component: UsersPending },
    { path: '/quanlynguoidung/nguoiduyetvoucher/:id', exact: true, name: 'Chi tiết', component: UserPending },


    { path: '/quanlycuahang/storepartner', exact: true, name: 'Quản lý xưởng', component: StorePartners },
    { path: '/quanlycuahang/storepartner/:id', exact: true, name: 'Chi tiết', component: StorePartner },

    { path: '/quanlysanpham/giasanpham', exact: true, name: 'Giá sản phẩm', component: PriceProducts },
    { path: '/quanlysanpham/giasanpham/:id', exact: true, name: 'Chi tiết giá sản phẩm', component: PriceProduct },

    { path: '/quanlysanpham/danhsachsanpham', exact: true, name: 'Danh sách sản phẩm', component: Products },
    // { path: '/quanlysanpham/danhsachsanpham/:where', exact: true, name: 'Danh sách sản phẩm', component: Products },
    { path: '/quanlysanpham/danhsachsanpham/:id', exact: true, name: 'Chi tiết danh sách sản phẩm', component: Product },
    { path: '/quanlysanpham/danhmucsanpham', exact: true, name: 'Danh mục sản phẩm', component: Categories },
    { path: '/quanlysanpham/danhmucsanpham/:id', exact: true, name: 'Danh mục sản phẩm', component: Category },
    { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
    { path: '/quanlycuahang/stores', exact: true, name: 'Danh sách cửa hàng', component: Stores },
    { path: '/quanlycuahang/storesuser', exact: true, name: 'Danh sách người dùng', component: StoreUsers },
    { path: '/quanlycuahang/storesuser/:name/:id', exact: true, name: 'Danh sách người dùng', component: StoreUsers },
    { path: '/quanlycuahang/stores/:id', exact: true, name: 'Chi tiết danh sách cửa hàng', component: Store },
    { path: '/quanlycuahang/storesarea', exact: true, name: 'Danh sách khu vực cửa hàng', component: StoreAreas },
    { path: '/quanlycuahang/storesarea/:id', exact: true, name: 'Chi tiết danh sách khu vực cửa hàng', component: StoreArea },
    { path: '/report/debt', exact: true, name: 'Danh sách công nợ ', component: Debts },
    { path: '/report/CustomerUseService', exact: true, name: 'Danh sách khách hàng sử dụng dịch vụ', component: CustomerUseService },
    { path: '/report/voucherPartner', exact: true, name: 'Thống kê sử dụng mã đối tác', component: ReportVoucherPartner },
    { path: '/report/debt/storeId=/:storeId/userId=/:userId', exact: true, name: 'Chi tiết', component: Debt },



    { path: '/rating-reviews/views', exact: true, name: 'Đánh giá', component: Ratings },
    { path: '/report/ratingStar', exact: true, name: 'Danh sách đánh giá', component: RatingStar },
    { path: '/report/ratingType', exact: true, name: 'Danh sách kiểu đánh giá', component: RatingType },
    { path: '/report/modals', name: 'Modals', component: Modals },
    { path: '/password/changepassword', name: 'Mật khẩu', component: Password },
    { path: '/password/resetpassword', name: 'Reset mật khẩu', component: ResetPassword },
    { path: '/password/changepasswordstore', name: 'Mật khẩu cửa hàng', component: PasswordStore },

    { path: '/users', exact: true, name: 'Danh sách người dùng', component: Users },
    { path: '/users/:id', exact: true, name: 'Chi tiết', component: User },

    { path: '/bill/view', exact: true, name: 'Hóa đơn', component: Bill },
    { path: '/bill/bill-cancel', exact: true, name: 'hóa đơn đã hủy', component: BillCancel },



    { path: '/report/email', exact: true, name: 'Hộp thư điện tử', component: Email },
    { path: '/report/sms', exact: true, name: 'Tin nhắn', component: Sms },
    { path: '/report/finish-work', exact: true, name: 'Công việc hoàn thành', component: FinishWork },
    { path: '/report/revenue-structure', exact: true, name: 'Cơ cấu doanh thu theo sản phẩm', component: RevenueStructure },

    { path: '/report/turnover', exact: true, name: 'Doanh số', component: TurnOver },
    { path: '/user/profile', exact: true, name: 'Thông tin cá nhân', component: PersonalInformation },
    { path: '/report/overtime', exact: true, name: 'Ngày tăng ca', component: OverTime },
    { path: '/report/partner-store/turnover', exact: true, name: 'Doanh số chuyển xưởng', component: PartnerTurnOver },
    { path: '/report/rating', exact: true, name: 'Đánh giá người dùng', component: Rating },
    { path: '/report/store/turnover', exact: true, name: 'Doanh số cửa hàng', component: StoreTurnOver },

    { path: '/specific/specific-pending', exact: true, name: 'Sản phẩm chờ duyệt', component: SpecificsPending },
    { path: '/specific/specific-approved', exact: true, name: 'Sản phẩm đã duyệt', component: SpecificsApproved },
    { path: '/quanlynguoidung/thongkethunhap', exact: true, name: 'Thống kê thu nhập người dùng VKShare', component: StatisticMonthly },

    { path: '/xephangkhachhang', exact: true, name: 'Xếp hạng khách hàng', component: CustomerRatings },
    { path: '/cauhinhbannerapp', exact: true, name: 'Cấu hình Banner APP', component: ConfigBanner },

    { path: '/danhmucsanphamtheoapp/danhmucsanpham/', exact: true, name: 'Danh mục sản phẩm', component: AppProductCategory },
    { path: '/danhmucsanphamtheoapp/danhmucmien/', exact: true, name: 'Danh mục miền', component: AppProductArea },
    { path: '/danhmucsanphamtheoapp/danhmucmien/:id', exact: true, name: 'Danh mục miền', component: AppProductArea },
    { path: '/danhmucsanphamtheoapp/danhmucdichvu/', exact: true, name: 'Danh mục dịch vụ theo miền', component: AppProductAreaService },
    { path: '/danhmucsanphamtheoapp/danhmucdichvu/:id', exact: true, name: 'Danh mục dịch vụ theo miền', component: AppProductAreaService },
    { path: '/danhmucsanphamtheoapp/danhsachsanpham/', exact: true, name: 'Danh sách sản phẩm', component: AppProductList },
    { path: '/danhmucsanphamtheoapp/danhsachsanpham/:id', exact: true, name: 'Danh sách sản phẩm', component: AppProductList },
    // { path: '/customerreviews', exact: true, name: 'Customer Reviews', component: CustomerReviews },
    // { path: '/danhsachcuahang', exact: true, name: 'Danh sach cua hang', component: DanhSachCuaHang },
    // { path: '/danhsachcuahang/:id', exact: true, name: 'chi tiet danh sach cua hang', component: ChiTietDanhSachCuaHang },

];

export default routes;
