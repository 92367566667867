// import langReducer from "./langReducer";
import uiReducer from "./uiReducer";
import userReducer from './userReducers';
// import cateState from './categoryReducer';
import { combineReducers } from "redux";
import listUsers from './listUsers'
import itemEditing from './itemEditing'
import listStores from './listStores'
import listStoreAreas from './listStoreAreas'
import listProducts from './listProducts'
import listCategories from './listCategories'
import listPriceProducts from './listPriceProducts'
import listVouchers from './listVouchers'
import listConfigs from './listConfigs'
import listStoreUsers from './listStoreUsers'
import listStorePartners from './listStorePartners'
import ratingReviews from './ratingReviews'
import listBill from './listBill'
import PartnerReducer from './partnerReducer'
// import search from './search'
// import filterTable from './filterTable'
// import sort from './sort'

const rootReducer = combineReducers({ listBill, ratingReviews, listStoreUsers, listStorePartners, listVouchers, listConfigs, listPriceProducts, listCategories, listProducts, listStoreAreas, listStores, uiReducer, userReducer, listUsers, itemEditing, PartnerReducer });

export default rootReducer;
