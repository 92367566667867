// import {
//   Breadcrumbs,
//   Cards,
//   Carousels,
//   Collapses,
//   Dropdowns,
//   Forms,
//   Jumbotrons,
//   ListGroups,
//   Navbars,
//   Navs,
//   Paginations,
//   Popovers,
//   ProgressBar,
//   Switches,
//   Tables,
//   Tabs,
//   Tooltips,
// } from './Base';
import { PersonalInformation } from './ThongTinNguoiDung'
import { UsersVoucher, UsersPending, StatisticMonthly } from './QuanLyNguoiDung'
import { Configs } from './CauHinhHeThong'
import { ButtonDropdowns, Buttons, Products, Categories, PriceProducts } from './QuanLySanPham';
import { Vouchers } from './QuanLyVoucher'
// import Charts from './Charts';
import Dashboard from './Dashboard';
import { Stores, StoreAreas, StorePartners } from './QuanLyCuaHang';
import { AppProductCategory, AppProductList, AreaCategory, AreaService } from './DanhMucSanPhamApp';
import { Modals, Debts, Email, Sms, FinishWork, TurnOver, OverTime, PartnerTurnOver, RatingStar, RatingType, Rating, StoreTurnOver } from './Report';
import { Ratings } from './Rating'
import { SpecificsApproved, SpecificsPending } from './LuuTruSanPham'
import { Login, Page404, Page500 } from './Pages';
// import { Colors, Typography } from './Theme';
// import Widgets from './Widgets';
import { Bills } from './HoaDon'
import * as api from './api/index'
import { ChangePassword, ResetPassword, ChangePasswordStore } from './Password'

export {

  Bills, RatingStar, RatingType,
  SpecificsApproved, SpecificsPending, StatisticMonthly,
  StoreTurnOver,
  Rating,
  OverTime,
  PartnerTurnOver,
  PersonalInformation,
  Configs,
  TurnOver,
  FinishWork,
  StorePartners,
  UsersVoucher,
  UsersPending,
  api,
  Sms,
  Email,
  Ratings,
  Vouchers,
  ResetPassword,
  ChangePassword,
  ChangePasswordStore,
  PriceProducts,
  Stores,
  StoreAreas,
  Page404,
  Page500,
  Login,
  Modals,
  Debts,
  ButtonDropdowns,
  Products,
  Categories,
  Buttons,
  Dashboard,
  AppProductCategory,
  AppProductList,
  AreaCategory,
  AreaService,
};

