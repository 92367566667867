import React, { Component } from 'react';
import {
  Badge, Card, CardBody, CardHeader, Col, Row, Table, Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  InputGroupAddon,
  InputGroupText,
  ModalHeader, CardImg, CardText,
  CardTitle, CardSubtitle
} from 'reactstrap';
import qs from 'query-string';
import { AppSwitch } from '@coreui/react'
import { api } from '../..'
import '../../../index.css'
import StarRatingComponent from 'react-star-rating-component';

class Registrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      billId: '',
      customerNote: '',
      barcode: ''
    };
  }

  // onStarClick(nextValue, prevValue, name) {
  //   this.setState({ rating: nextValue });
  // }
  componentDidMount() {
    this.actCheckBarcode()
  }
  componentWillMount() {

  }
  actCheckBarcode() {
    var barcode = qs.parse(this.props.location.search)
    if (barcode.barcode) {
      this.setState({ barcode: barcode.barcode })
    }
    else {
      // alert('Link không tồn tại !')
      // this.props.history.push('/login')
    }
  }
  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  async actRegistration() {
    const { receiveAddress, billId } = this.state;
    var barcode = qs.parse(this.props.location.search)
    if (barcode) {
      var registration = {
        barCode: barcode.barcode,
        billId: parseInt(billId),
        receiveAddress: receiveAddress,
      }
      var result = await api.dataService.actRegistration(registration)
      if (result) {
        alert('Chúng tôi đã ghi nhận yêu cầu của quý khách! Chúng tôi sẽ xử lý yêu cầu của quý khách trong thời gian sớm nhất.')
        // api.api.showConfirm('Thông báo', 'Cảm ơn', 'OK', 'No', 'OK', 'No')
        this.props.history.push('/404')
      }
      else {
        alert('Lỗi hệ thống !')
      }
    }
    else {
      alert('Barcode không tồn tại !')
    }
  }
  render() {
    const { receiveAddress, billId } = this.state;
    var barcode = qs.parse(this.props.location.search)
    // console.log('uuu', this.state.barcode)
    return (
      <div className="customerreviews">
        <Alert color="danger" className="center">
          Đăng ký nhận tại nhà !
      </Alert>
        <Form>
          <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold">Mã hóa đơn : </Label>
            <Col sm={9}>
              <Input type="number" name="billId" value={billId} id="billId" min={0} step={1} onChange={this.onChange.bind(this)} placeholder="Mời bạn nhập vào mã hóa đơn ..." />
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label for="exampleEmail" sm={3}>BillId</Label>
            <Col sm={9}>
              <Input type="number" name="billId" id="billId" placeholder="with a placeholder" />
            </Col>
          </FormGroup> */}
          {/* <FormGroup row>
            <Label for="exampleEmail" sm={3} className="bold">Chất lượng dịch vụ : </Label>
            <Col sm={9}>
              <StarRatingComponent
                name="rate1"
                starCount={5}
                value={rating}
                onStarClick={this.onStarClick.bind(this)}
              />
            </Col>
          </FormGroup> */}

          <FormGroup row>
            <Label for="exampleText" sm={3} className="bold">Địa chỉ :</Label>
            <Col sm={9}>
              <Input type="textarea" name="receiveAddress" value={receiveAddress} id="receiveAddress" onChange={this.onChange.bind(this)} />
            </Col>
          </FormGroup>

          <FormGroup check row>
            <Col className="right">
              <Button className="right" color="success" onClick={this.actRegistration.bind(this)}>Gửi</Button>
            </Col>
          </FormGroup>
        </Form>

      </div>
    )
  }
}

export default Registrations;

