import React, { Component } from 'react';
import {
 Card, CardBody, CardHeader, Col, Row, Table, Button,
  Alert,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import moment from 'moment'
import '../../../index.css'
import { api } from '../../../views'
import 'react-select/dist/react-select.css'
import 'react-virtualized-select/styles.css'
import Select from "react-virtualized-select";
import { connect } from "react-redux";

function UserRow(props) {
  const data = props.data
  let index = props.index
  let formatCurrency  = props.formatCurrency
  const url = `#/quanlysanpham/danhsachsanpham/${data.productId.id}`
  const url2 = `#/quanlycuahang/stores/${data.storeId.id}`


  async function handleUpdateNo() {
    const data = props.data
    
    var id = data.id
    var datas = {
      storeSpecificProductId: id,
      action: false
    }
    if (confirm('Bạn chắc chắn muốn hủy đơn sản phẩm này ?')) { //eslint-disable-line
      api.dataService.actUpdateStoreSpecificProduct(datas)
      // api.dataService.actFetchUsersRequest(0, 10, '', '', '')
      window.location.reload(true);
    }

  }
  async function handleUpdateYes() {
    const data = props.data
    
    var id = data.id
    var datas = {
      storeSpecificProductId: id,
      action: true
    }
    if (confirm('Bạn chắc chắn muốn duyệt giá sản phẩm ?')) { //eslint-disable-line
      await api.dataService.actUpdateStoreSpecificProduct(datas);
      // api.dataService.actFetchUsersRequest(0, 10, '', '', '')
    }

    window.location.reload(true);

  }
  return (
    <tr >
      <th scope="row" className="text-center"><a href={url}>{index+1}</a></th>
      <td className="text-left">
      <div className="small ">
                            <span className="bold">Tên</span> | {data.productId.name}
                          </div>
                          <div className="small ">
                            <span className="bold">Mô tả</span> | {data.productId.description}
                          </div>
                          <div className="small ">
                            <span className="bold"><a href={url}>Xem chi tiết</a></span> 
                          </div>
      </td>
      <td className="text-left">
      <div className="small ">
                            <span className="bold">Tên</span> | {data.storeId.name}
                          </div>
                          <div className="small ">
                            <span className="bold">Mô tả</span> | {data.storeId.description}
                          </div>
                          <div className="small ">
                            <span className="bold"><a href={url2}>Xem chi tiết</a></span> 
                          </div>
      </td>
      <td className="text-center">{moment(data.createdAt).format('lll')}</td>
      <td className="text-center">{formatCurrency(data.price) }VNĐ</td>
      <td className="text-center"> 
      <Row><Col xs="6">
      <Button outline color="success" size="sm" block onClick={handleUpdateYes}>Đồng ý</Button> 
      </Col> <Col xs="6">
      <Button outline color="danger" size="sm" block onClick={handleUpdateNo}>Hủy bỏ</Button></Col></Row> 
       </td>
    </tr >
  )
}

class SpecificsPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      account: '',
      phone: '',
      datas: [],
      selectValue:'',
      listProducts:[],
      listStores:[],
      storeId:''
    };
    this.toggleSuccess = this.toggleSuccess.bind(this);
  }

  toggleSuccess() {
    this.setState({
      success: !this.state.success,
    });
  }
  componentDidMount() {
    this.actGetStoreSpecificProduct()
    this.actGetProduct()
    this.actGetStore()
  }
  async actGetStore(){
    var result = await api.dataService.actFetchStoresRequest('','','','','')
    console.log('ggg',result)
    this.setState({listStores:result.data.data})
  }
  async actGetProduct(){
    var result = await api.dataService.actFetchProductsRequest('','','','','')
    this.setState({listProducts:result.data})
  }
  async actGetStoreSpecificProduct(where) {
    let where2 = Object.assign({}, where);
    if(!where2){
      where2={}
    }
    where2.isActive=false
    where2.isDelete=false
    var result = await api.dataService.actGetStoreSpecificProducts('', '', '',where2,'')
    this.setState({ datas: result.data.data })
  }

  onChange = async (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    await this.setState({
      [name]: value
    });
  }
  formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
  onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.onSubmitSearch();
    }
  }
  onSubmitSearch = async e => {
    e.preventDefault();
    e.target.reset()
    var {  selectValue,storeId } = this.state;
    let where = {};

    if (selectValue && selectValue.value) {
      where.productId = selectValue.value 
    }
    
    if (storeId && storeId.value) {
      where.storeId = storeId.value
    } 

    
    this.setState({ currentPage: 1 })
    await this.props.history.push('?page=' + 1 + '&productId=' + (selectValue.value || '') +  '&storeId=' + (storeId.value || '') )
    this.actGetStoreSpecificProduct(where)
  }
  render() {
    var { datas, listStores, storeId,selectValue,listProducts } = this.state;
    const options = listProducts.map((a) => {
      return (
          { label:a.name, value: a.id,key: a.id }
        
      )
    })
    const options2 = listStores.map((a) => {
      return (
          { label:a.name, value: a.id,key: a.id }
        
      )
    })
   
    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> Bộ lọc
              </CardHeader>
              <CardBody>
                <Form onSubmit={this.onSubmitSearch}>
                <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="exampleCity">Sản phẩm</Label>
                        <Select options={options}  
                         onChange={(selectValue) => this.setState({ selectValue })}
                         value={selectValue}
                         placeholder="Search"
                         clearable={false}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                    <FormGroup>
                        <Label for="exampleCity">Cửa hàng</Label>
                        <Select options={options2}  
                         onChange={(storeId) => this.setState({ storeId })}
                         value={storeId}
                         placeholder="Search"
                         clearable={false}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup className="form-actions">
                    <Button type="submit" size="sm" color="primary">Tìm kiếm</Button>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify"></i> {datas.length > 0 ? `Dữ liệu (${datas.length} mục)` : 'Đang xử lý ...'}
              </CardHeader>
              <CardBody>
                {datas.length > 0 ?
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">#</th>
                        <th scope="col" className="text-left">Thông tin sản phẩm</th>
                        <th scope="col" className="text-left">Thông tin cửa hàng</th>
                        <th scope="col" className="text-center">Thời gian tạo</th>
                        <th scope="col" className="text-center">Thành tiền</th>
                        {/* <th scope="col">Khóa tài khoản</th> */}
                        <th scope="col" className="text-center">Hành động</th>
                      </tr>
                    </thead>
                    {datas.map((data, index) =>
                      <tbody key={index}>
                        <UserRow data={data} index={index} formatCurrency={this.formatCurrency}/>
                      </tbody>)}

                  </Table> : <Alert color="warning" className="center">
                    Không có dữ liệu !
      </Alert>}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <Modal isOpen={this.state.success} toggle={this.toggleSuccess}
          className={'modal-success ' + this.props.className}>
          <ModalHeader toggle={this.toggleSuccess}>Add User</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.onSubmit}>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Tài khoản</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="username" name="username" value={username} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mật khẩu</InputGroupText>
                  </InputGroupAddon>
                  <Input type="password" id="password" name="password" value={password} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-asterisk"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Email</InputGroupText>
                  </InputGroupAddon>
                  <Input type="email" id="email" name="email" value={email} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Họ và tên</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="fullname" name="fullname" value={fullname} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Địa chỉ</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="address" name="address" value={address} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Mô tả chi tiết</InputGroupText>
                  </InputGroupAddon>
                  <Input type="text" id="description" name="description" value={description} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>identityCard</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="identityCard" name="identityCard" value={identityCard} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>Số điện thoại</InputGroupText>
                  </InputGroupAddon>
                  <Input type="number" id="phone" name="phone" value={phone} onChange={this.onChange.bind(this)} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup row>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>RoleIds</InputGroupText>
                  </InputGroupAddon>
                  <Input type="select" name="roleId" value={roleId} id="roleId" onChange={this.onChange.bind(this)} >
                    <option value="">-- select --</option>
                    {roleIds.length > 0 ? roleIds.map(a => {
                      return (< option key={a.id} value={a.id} > {a.name}</option>)
                    }) : ''}

                  </Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText><i className="fa fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <FormGroup className="form-actions">
                <Button type="submit" size="sm" color="primary">Submit</Button>
              </FormGroup>
            </Form>
          </ModalBody>
        </Modal> */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userReducer: state.userReducer,
    listUsers: state.listUsers,
  }
}
export default connect(mapStateToProps)(SpecificsPending);

