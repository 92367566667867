import React from 'react';

const Loading = (props) => {
    let style = {
        loader: {
            display: props.isOpen ? "flex" : "none"
        }
    }
    return (
        <div className="loader-container" style={style.loader}>
            <div class="loader" ></div>
        </div>
    )
}



export default Loading